.home-carousel {
  position: relative;
  .owl-nav {
    pointer-events: none;

    button {
      pointer-events: auto;
    }
  }

  .slide-unpublished {
    &::before {
      background-color: red;
      bottom: 50px;
      color: $white;
      content: "Non publié";
      font-size: 12px;
      left: 50%;
      margin-left: -50px;
      padding: 3px;
      position: absolute;
      text-align: center;
      width: 100px;
      z-index: 99999999;
    }

    .bg__image {
      opacity: 0.5;
    }
  }
  @include media-breakpoint-down(sm) {
    background-color: theme-color("secondary");
    color: white;
    h2 {
      color: white;
    }
  }
}
