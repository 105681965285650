.home_slider {
  @include media-breakpoint-up(lg) {
    margin-bottom: 100px;
    padding-left: 2rem;
    padding-right: 2rem;
    margin-left: auto;
    margin-right: auto;
    max-width: 1500px;
    .owl-stage-outer {
      overflow: visible;
    }
  }
  .owl-item {
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease;
    &.active {
      opacity: 1;
      visibility: visible;
    }
  }
  .slider__wrapper {
    @include media-breakpoint-up(lg) {
      position: relative;
      .item {
        display: flex;
        align-items: center;
        justify-content: center;
        &.content__ok {
          justify-content: flex-end;
        }
      }
      .slider__content {
        background: #ffffff;
        box-shadow: 30px -10px 80px 20px rgba(0, 0, 0, 0.07);
        border-radius: 8px;
        padding: 2rem;
        position: absolute;
        left: 0;
        z-index: 1;
        flex: 1;
        width: 40%;
        height: 70%;
        display: flex;
        align-items: center;
        h2 {
          color: theme-color("secondary");
          margin-bottom: 1.5rem;
          line-height: 1.5;
        }
        .btn {
          margin-top: 10px;
        }
      }
      .slider_img {
        min-width: 70%;
        img {
          min-width: 100%;
          height: auto;
          border-radius: 5px;
        }
      }
    }
    @include media-breakpoint-only(md) {
      .slider__content {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 10px;
        background-color: rgba(white, 0.9);
        h2{
          color: theme-color('secondary');
          font-size: 22px;
        }
      }
    }
    @include media-breakpoint-down(md) {
      .item {
        display: flex;
        flex-direction: column-reverse;
        text-align: center;
        .slider__content {
          padding: 1rem;
          .btn {
            font-size: 80%;
          }
        }
      }
    }
    @include media-breakpoint-down(sm) {
      .item {
        height: 100%;
      }
      .slider_img {
        height: 400px;
        position: relative;
        img {
          height: 100%;
          object-fit: cover;
        }
      }
      .content__ok {
        .slider_img {
        }
        .slider__content {
          z-index: 1;
          background-color: rgba(theme-color("dark"), 0.65);
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
  .owl-nav {
    @include media-breakpoint-up(md) {
      position: absolute;
      background-color: white;
      right: 20px;
      bottom: 20px;
    }
    @include media-breakpoint-down(md) {
      display: block;
    }
    padding: 0.3rem 1rem;
    border-radius: 5px;
    button + button {
      &::before {
        content: "/";
        margin-right: 0.5rem;
        color: #ccc;
      }
    }
    button {
      &:hover {
        background: transparent !important;
        color: theme-color("primary") !important;
        &::before {
          color: #ccc;
        }
      }
    }
  }
}
