.img-thumbnail-wrapper {
  margin-bottom: 30px;
}

.img-thumbnail-img {
  position: relative;

  img {
    display: block;
  }
}

.img-align-center {
  img {
    margin: 0 auto;
  }
}

.img-align-right {
  img {
    margin: 0 0 0 auto;
  }
}

.img-align-left {
  img {
    margin: 0 auto 0 0;
  }
}

.img-author-icon {
  @extend .fas;
  bottom: 0;
  color: $white;
  cursor: pointer;
  padding: .5em;
  position: absolute;
  right: 0;
  text-shadow: 0 0 5px rgba(0, 0, 0, .5);
  z-index: 9999998;

  &::before {
    content: fa-content($fa-var-info-circle);
  }

  &:hover,
  &:focus {
    color: theme-color('secondary');
  }
}

