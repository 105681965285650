html,
body {
  height: 100%;
  margin: 0;
}

body {
  font-family: $montserrat-regular;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: darken($white, 60);
  overflow-x: hidden;
  line-height: 1.5;
  font-size: 16px;
}
.handofsean {
  font-family: $handofsean;
  font-size: 24px;
  letter-spacing: 1px;
}

a {
  transition: 0.5s all ease;

  &:hover {
    text-decoration: none;
  }

  &:link {
    text-decoration-skip: ink;
  }
}

.btn {
  padding: 10px;
  font-family: $montserrat-semi;
  border-radius: 4px;
  @include media-breakpoint-up(lg) {
    &.btn-primary {
      color: $white !important;
      box-shadow: 0 6px 17px 4px rgba(123, 174, 38, 0.14);
    }
    &.btn-secondary {
      box-shadow: 0 6px 17px 4px rgba(88, 32, 150, 0.14);
    }
  }
}

.btn.btn-sm {
  padding: 6px;
  text-transform: inherit;
}

.breadcrumb {
  background-color: transparent;
  margin-bottom: 0;
  font-size: 80%;
  li {
    @extend .breadcrumb-item !optional;
  }
}

main {
  min-height: 100%;
  @include media-breakpoint-down(md) {
    overflow-x: hidden;
  }
}

.file {
  display: inline-block;
  padding: 5px;
  font-weight: bold;
  font-size: 14px;

  img {
    width: 30px;
    height: auto;
  }
}

.kapt {
  img {
    width: 50px;
    height: auto;
  }
}

.error-img {
  max-width: 400px;
  opacity: 0.3;
  margin-bottom: 1rem;
  filter: grayscale(100%);
}

#errorpage {
  h1 {
    color: theme-color("primary");
  }

  p {
    max-width: 600px;
    margin: 10px auto;
  }
}

.d-block {
  display: block;
}

.modal {
  .grecaptcha-badge {
    position: unset !important;
    margin-top: 10px;
  }
}
.grid__link {
  list-style: none;
  padding-left: 0;
  display: grid;
  a {
    min-width: 80%;
  }
  gap: 0.5rem;
  @include media-breakpoint-up(lg) {
  }
}

#id_content {
  main {
    position: relative;
    background-image: url(../img/fleur2.svg);
    background-repeat: no-repeat;
    background-position: left 400px;
    background-attachment: fixed;
    &::before {
      content: "";
      position: absolute;
      background-image: url(../img/bg3.svg);
      background-repeat: no-repeat;
      background-size: contain;
      top: 20%;
      right: 0;
      height: 290px;
      width: 43px;
      transform: scale(-1) translateY(-50%);
    }
    p a:not([class]) {
      box-shadow: inset 0 -5px 0 0 rgba(theme-color("primary"), 0.2);
    }
  }
}

.owl-carousel .owl-stage,
.owl-carousel.owl-drag .owl-item {
  -ms-touch-action: auto;
  touch-action: auto;
}

.grecaptcha-badge {
  display: none !important;
}


.icon_consommons{
  background-image: url(../img/consommons_bio.png);
  display: inline-block;
  background-repeat: no-repeat;
  width: 72px;
  height: 59px;
}


@media only screen and ( -webkit-min-device-pixel-ratio: 1.3 ),
only screen and (    min--moz-device-pixel-ratio: 1.3 ),
only screen and (      -o-min-device-pixel-ratio: 2.6/2 ), /* returns 1.3, see Dev.Opera */
only screen and (         min-device-pixel-ratio: 1.3 ),
only screen and ( min-resolution: 124.8dpi ),
only screen and ( min-resolution: 1.3dppx ) {

  .icon_consommons{
    background-image: url(../img/consommons_bio@2x.png);
    background-size: contain;
  }

}



.djangocms-popup_overlay{
  background-color: rgba(black, .8);
  box-shadow: 0 0 15px 0 #ddd;
  visibility: hidden;
  position: fixed;
  top: 0%;
  left: 00%;
  right: 0;
  bottom: 0;
  z-index: 999999;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0;
  @include media-breakpoint-up(lg) {
    padding: 4rem;

  }
  @include media-breakpoint-down(lg) {
    padding: 1.5rem;

  }
  .popup_content{
    background-color: white;
    padding: 3rem;
    position: relative;
    @include media-breakpoint-down(lg) {
      padding: 1rem;
    }
  }
  .djangocms-popup-close{
    background-color: theme-color('primary');
    color: white;
    padding: 1rem;
    border-radius: 2rem;
    font-size: 40px;
    top: -20px;
    right: -20px;
    &.djangocms-popup-reopen{
      position: fixed;
      bottom: 15px;
      right: 15px;
      top: auto;
      padding: 0;
      transform: scale(1);
      animation: pulse-black 2s infinite;
      &::before{
        content: "";
        background-image: url(../img/bio.png);
        width: 50px;
        height: 50px;
        display: block;
        background-size: contain;
      }
    }
  }
}
@keyframes pulse-black {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(theme-color('secondary'), 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px theme-color('secondary');
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 theme-color('secondary');
  }
}


.password__div{
  position: relative;
  i#togglePassword{
    position: absolute;
    bottom: 29px;
    right: 17px;
  }
}
