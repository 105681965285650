@import 'node_modules/leaflet/dist/leaflet';

.leaflet-popup {
  width: 300px;
}

#id_subscribers,
#id_detail {
  .page__header {
    background-color: theme-color('primary');
    background-image: linear-gradient(-225deg, #87bc2f 15%, #7ace5c 100%);
    display: block;
    text-align: left;
    a {
      color: $white !important;
    }
    .logo__member{
      margin-right: 20px;
    }
    .breadcrumb{
      padding-left: 0;
    }
  }
}

#id_detail {
  .breadcrumb {
    &-item {
      a {
        color: theme-color('black-project');
      }

      span {
        color: theme-color('secondary');
        font-weight: bold;
      }
    }
  }
}
#filters {
  transition: all 0.3s linear;
}

.directory {
  @include media-breakpoint-up(lg) {
    display: flex;
    position: relative;
    transition: all 0.3s linear;
    background-color: darken($white, 2);
  }

  .filters {
    min-width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    span{
      font-weight: bold;
      @include media-breakpoint-down(sm) {
        display: none;
      }
    }
  }

  &__list {
    padding: 10px;
    position: relative;
    overflow-x: hidden;
    border-left: 1px solid #eee;
    .name__search{
      display: flex;
      position: absolute;
      margin-left: 80px;
      margin-top: 1px;

      border-radius: 4px;
      input{
        padding: 10px;
        height: 46px;
        &[type="text"]{
          border: 1px solid #eee;
          @include media-breakpoint-up(lg) {
            min-width: 270px;

          }
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
        }
        &[type="submit"]{
          background-color: theme-color('primary');
          color: white;
          font-weight: bold;
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
        }
      }
    }
    @include media-breakpoint-up(lg) {
      min-width: 50%;
      max-width: 50%;
      width: 50%;
      .subscriber__list{
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-columns: repeat( auto-fill, minmax(250px, 1fr) );
        gap: 20px;
      }
    }
    @include media-breakpoint-only(md) {
      .subscriber__list{
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;
      }
    }

  }

  &__item {
    text-align: center;
    cursor: pointer;
    transition: box-shadow 0.3s;
    height: 100%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    border: 1px solid #eee;

    box-shadow: 0 9px 33px 0 rgba(0, 0, 0, 0.12);
    position: relative;
    width: 100%;
    img{
      width: 100%;
    }
    .information{
      padding-top: 10px;
    }
    .member {
      position: absolute;
      top: 10px;
      right: 27px;
      padding: 5px;
      z-index: 1;
      span{
        display: inline-block;
        background-image: url(../img/pastille.png);
        width: 30px;
        height: 30px;
        background-size: cover;
      }

      i {
        color: theme-color('secondary');
        font-size: 26px;
      }
    }
  }

  .item {
    position: relative;

    &__name {
      font-size: 18px;
      color: theme-color('secondary');
      margin-top: 10px;
      border-top: 1px solid #eee;
    }

    &__type {
      font-style: italic;
      padding: 10px 10px 0;
      font-size: 1rem;
    }

    &__locality {
      padding: 10px 10px 0;
      font-family: $montserrat-medium;
    }

    &__locality {
      color: theme-color('text-color');
      font-size: 14px;
    }
  }

  &__map {
    width: 50%;
    min-width: 50%;
    max-width: 50%;
    background-color: #ccc;
    position: sticky !important;
    top: 0;
    right: 0;
    left: 0;
    height: 100vh;
  }
}

.directory__print li {
  display: flex;
  margin-bottom: 2em;

  .item__name,
  .item__adress {
    flex: 0 0 45%;
    margin-right: 1em;
  }

  .item__logo {
    flex: 0 0 10%;
    margin-right: 1em;
    text-align: right;
  }

  .item__member-badge {
    display: inline;
    color: theme-color('primary');
    span{
      display: inline-block;
      background-image: url(../img/pastille.png);
      width: 30px;
      height: 30px;
      background-size: cover;
    }
  }
}

.print__header {
  display: flex;
  padding: 1em;
  margin-bottom: 2em;
  border-bottom: solid 1px #dedede;
  align-items: center;

  img {
    flex: 1 0 20%;
    max-width: 278px;
    margin-right: 2em;
  }

  h1 {
    flex: 3 0 80%;
  }
}

.site-domain {
  margin-bottom: 3em;
  text-align: center;
}

.loader {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  background-color: rgba(theme-color('primary'), 0.7);
  z-index: 1;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: $white;
  font-weight: bold;

  span {
    display: block;
  }
}

.pagination-block {
  width: 100%;
}

.sidebar {
  // position: fixed;
  // overflow: scroll;
  z-index: 1;
  // left: 0;
  // top: 90px;
  // width: 300px;
  bottom: 0;
  background: $white;
  // transform: translateX(-100%);
  transition: all 0.3s linear;
  border-right: 1px solid #ccc;

  &.is__visible {
    transform: translateX(0%);
  }

  .close__sidebar {
    color: $white;
  }

  form {
    padding: 10px;

    span {
      display: block;
      font-size: 14px;
      margin-bottom: 5px;
    }

    ul {
      @extend .list-unstyled;

      label {
        font-size: 14px;
        font-weight: bold;
      }
    }

    select,
    input[type='text'] {
      @extend .form-control;
    }

    .form-check {
      label {
        font-size: 14px;
      }
    }

    .form-control,
    .form-check {
      margin-bottom: 10px;
    }

    #id_locality {
      width: 100%;
    }
  }

  .form-group {
    input[type='text'] {
      display: block;
      width: 100%;
      padding: 10px;
    }

    > label {
      font-weight: bold;
      color: theme-color('primary');
    }

    ul {
      padding-left: 20px;
    }
  }

  &__title {
    background-color: theme-color('secondary');
    padding: 10px;
    display: flex;
    align-items: center;
    height: 50px;
    position: relative;

    span {
      position: absolute;
      right: 0;
      padding: 10px;
      background-color: lighten(theme-color('secondary'), 5);
      top: 0;
      bottom: 0;
      display: block;
    }

    h4 {
      margin-bottom: 0;
      color: $white;
    }
  }

}
.logo__member {
  padding: 20px;
  background: $white;
  border-radius: 20px;
  box-shadow: 0 2px 44px 0 rgba(0, 0, 0, 0.21);
  border-radius: 8px;
  margin-right: 20px;

  @include media-breakpoint-down(sm) {
    margin: 0 auto 2rem;
  }
}

#id_detail {
  .detail__title {
    padding-top: 20px;
    h1 {
      font-size: 30px;
    }
  }

  @include media-breakpoint-up(md) {
    section {
      padding: 3rem;
    }
  }

  @include media-breakpoint-down(md) {
    section {
      padding: 2rem 0.5rem;
    }
  }

  .page__header {
    background: $white;
    position: relative;

    @include media-breakpoint-only(md) {
      margin-top: 0;
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      height: 170px;
      background-image: linear-gradient(-225deg, #87bc2f 15%, #7ace5c 100%);
    }

    &::after {
      content: '';
      position: absolute;
      top: -90px;
      right: 20%;
      background-image: url(../img/feuille.svg);
      height: 400px;
      width: 290px;
      background-repeat: no-repeat;
      pointer-events: none;
    }
  }

  .map {
    height: 400px;
    max-width: 800px;
    background: #ccc;
  }

  section.gray {
    background: #eee;

    ul.list {
      list-style-type: none;

      li {
        position: relative;
        line-height: 2.5;

        &::before {
          font-family: 'Font Awesome 5 Free';
          font-weight: 900;
          content: '\f00c';
          font-size: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: theme-color('primary');
          height: 16px;
          width: 16px;
          border-radius: 16px;
          position: absolute;
          background-color: rgba(theme-color('primary'), 0.3);
          top: 50%;
          margin-top: -8px;
          left: -30px;
        }
      }
    }
  }

  @include media-breakpoint-up(md) {
    .map__container {
      position: relative;

      address {
        background: $white;
        box-shadow: 0 9px 33px 0 rgba(0, 0, 0, 0.12);
        padding: 4rem;
        max-width: 400px;
        min-width: 350px;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        z-index: 999;

        .address {
          margin-bottom: 1.5rem;
          font-weight: bold;
        }
      }
    }
  }

  @include media-breakpoint-down(sm) {
    .page__title {
      .container {
        padding: 0;
      }
    }

    .detail__title {
      text-align: center;
      width: 100%;
      border-top: 1px solid darken(theme-color('primary'), 5);
      padding-top: 1rem;
    }
  }

  @include media-breakpoint-down(md) {
    .page__header {
      background: theme-color('primary');
      padding: 1rem;

      &::before {
        height: 120px;
        background: transparent;
      }

      .breadcrumb {
        padding: 0;
      }
    }

    .detail__title {
      h1 {
        color: $black;
      }
    }

    .map__container {
      address {
        padding: 20px;
        background: #eee;
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  .directory{
    display: block;
  }
  .directory__map {
    display: none;
  }

  .directory__list {
    width: 100%;
  }
  .page__header {
    padding: 1rem;
  }
  .directory__list{
    max-width: 100%;
  }
}

.z-index {
  z-index: 1;
  position: relative;
}

.wrapper__block {
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  .sidebar{
    display: none;
  }
  &.is__sliding{
    grid-template-columns: 300px 1fr;
    .sidebar{
      display: block;
    }
  }
  #print {
    &::before {
      content: "\f02f";
      @extend .fas;
      margin-right: .5em;
    }
  }
  @include media-breakpoint-down(sm) {
    padding-top: 10px;
    .filters__buttons {
      position: static;
    }
  }
}
