// SCSS variables are information about icon's compiled state, stored under its original file name
//
// .icon-home {
//   width: $icon-home-width;
// }
//
// The large array-like variables contain all information about a single icon
// $icon-home: x y offset_x offset_y width height total_width total_height image_path;
//
// At the bottom of this section, we provide information about the spritesheet itself
// $spritesheet: width height image $spritesheet-sprites;
$sprite-f-r-name: 'sprite_FR';
$sprite-f-r-x: 390px;
$sprite-f-r-y: 231px;
$sprite-f-r-offset-x: -390px;
$sprite-f-r-offset-y: -231px;
$sprite-f-r-width: 28px;
$sprite-f-r-height: 20px;
$sprite-f-r-total-width: 452px;
$sprite-f-r-total-height: 355px;
$sprite-f-r-image: '../img/sprite.png';
$sprite-f-r: (390px, 231px, -390px, -231px, 28px, 20px, 452px, 355px, '../img/sprite.png', 'sprite_FR', );
$sprite-animer-sa-marque-name: 'sprite_animer-sa-marque';
$sprite-animer-sa-marque-x: 91px;
$sprite-animer-sa-marque-y: 104px;
$sprite-animer-sa-marque-offset-x: -91px;
$sprite-animer-sa-marque-offset-y: -104px;
$sprite-animer-sa-marque-width: 91px;
$sprite-animer-sa-marque-height: 91px;
$sprite-animer-sa-marque-total-width: 452px;
$sprite-animer-sa-marque-total-height: 355px;
$sprite-animer-sa-marque-image: '../img/sprite.png';
$sprite-animer-sa-marque: (91px, 104px, -91px, -104px, 91px, 91px, 452px, 355px, '../img/sprite.png', 'sprite_animer-sa-marque', );
$sprite-animer-sa-marque-hover-name: 'sprite_animer-sa-marque_hover';
$sprite-animer-sa-marque-hover-x: 182px;
$sprite-animer-sa-marque-hover-y: 104px;
$sprite-animer-sa-marque-hover-offset-x: -182px;
$sprite-animer-sa-marque-hover-offset-y: -104px;
$sprite-animer-sa-marque-hover-width: 91px;
$sprite-animer-sa-marque-hover-height: 91px;
$sprite-animer-sa-marque-hover-total-width: 452px;
$sprite-animer-sa-marque-hover-total-height: 355px;
$sprite-animer-sa-marque-hover-image: '../img/sprite.png';
$sprite-animer-sa-marque-hover: (182px, 104px, -182px, -104px, 91px, 91px, 452px, 355px, '../img/sprite.png', 'sprite_animer-sa-marque_hover', );
$sprite-approve-invoice-name: 'sprite_approve-invoice';
$sprite-approve-invoice-x: 322px;
$sprite-approve-invoice-y: 201px;
$sprite-approve-invoice-offset-x: -322px;
$sprite-approve-invoice-offset-y: -201px;
$sprite-approve-invoice-width: 22px;
$sprite-approve-invoice-height: 25px;
$sprite-approve-invoice-total-width: 452px;
$sprite-approve-invoice-total-height: 355px;
$sprite-approve-invoice-image: '../img/sprite.png';
$sprite-approve-invoice: (322px, 201px, -322px, -201px, 22px, 25px, 452px, 355px, '../img/sprite.png', 'sprite_approve-invoice', );
$sprite-commercialiser-name: 'sprite_commercialiser';
$sprite-commercialiser-x: 364px;
$sprite-commercialiser-y: 174px;
$sprite-commercialiser-offset-x: -364px;
$sprite-commercialiser-offset-y: -174px;
$sprite-commercialiser-width: 87px;
$sprite-commercialiser-height: 57px;
$sprite-commercialiser-total-width: 452px;
$sprite-commercialiser-total-height: 355px;
$sprite-commercialiser-image: '../img/sprite.png';
$sprite-commercialiser: (364px, 174px, -364px, -174px, 87px, 57px, 452px, 355px, '../img/sprite.png', 'sprite_commercialiser', );
$sprite-commercialiser-hover-name: 'sprite_commercialiser_hover';
$sprite-commercialiser-hover-x: 200px;
$sprite-commercialiser-hover-y: 201px;
$sprite-commercialiser-hover-offset-x: -200px;
$sprite-commercialiser-hover-offset-y: -201px;
$sprite-commercialiser-hover-width: 87px;
$sprite-commercialiser-hover-height: 57px;
$sprite-commercialiser-hover-total-width: 452px;
$sprite-commercialiser-hover-total-height: 355px;
$sprite-commercialiser-hover-image: '../img/sprite.png';
$sprite-commercialiser-hover: (200px, 201px, -200px, -201px, 87px, 57px, 452px, 355px, '../img/sprite.png', 'sprite_commercialiser_hover', );
$sprite-export-name: 'sprite_export';
$sprite-export-x: 0px;
$sprite-export-y: 201px;
$sprite-export-offset-x: 0px;
$sprite-export-offset-y: -201px;
$sprite-export-width: 100px;
$sprite-export-height: 78px;
$sprite-export-total-width: 452px;
$sprite-export-total-height: 355px;
$sprite-export-image: '../img/sprite.png';
$sprite-export: (0px, 201px, 0px, -201px, 100px, 78px, 452px, 355px, '../img/sprite.png', 'sprite_export', );
$sprite-export-hover-name: 'sprite_export_hover';
$sprite-export-hover-x: 100px;
$sprite-export-hover-y: 201px;
$sprite-export-hover-offset-x: -100px;
$sprite-export-hover-offset-y: -201px;
$sprite-export-hover-width: 100px;
$sprite-export-hover-height: 78px;
$sprite-export-hover-total-width: 452px;
$sprite-export-hover-total-height: 355px;
$sprite-export-hover-image: '../img/sprite.png';
$sprite-export-hover: (100px, 201px, -100px, -201px, 100px, 78px, 452px, 355px, '../img/sprite.png', 'sprite_export_hover', );
$sprite-innover-name: 'sprite_innover';
$sprite-innover-x: 182px;
$sprite-innover-y: 0px;
$sprite-innover-offset-x: -182px;
$sprite-innover-offset-y: 0px;
$sprite-innover-width: 91px;
$sprite-innover-height: 97px;
$sprite-innover-total-width: 452px;
$sprite-innover-total-height: 355px;
$sprite-innover-image: '../img/sprite.png';
$sprite-innover: (182px, 0px, -182px, 0px, 91px, 97px, 452px, 355px, '../img/sprite.png', 'sprite_innover', );
$sprite-innover-hover-name: 'sprite_innover_hover';
$sprite-innover-hover-x: 0px;
$sprite-innover-hover-y: 104px;
$sprite-innover-hover-offset-x: 0px;
$sprite-innover-hover-offset-y: -104px;
$sprite-innover-hover-width: 91px;
$sprite-innover-hover-height: 97px;
$sprite-innover-hover-total-width: 452px;
$sprite-innover-hover-total-height: 355px;
$sprite-innover-hover-image: '../img/sprite.png';
$sprite-innover-hover: (0px, 104px, 0px, -104px, 91px, 97px, 452px, 355px, '../img/sprite.png', 'sprite_innover_hover', );
$sprite-lancer-name: 'sprite_lancer';
$sprite-lancer-x: 364px;
$sprite-lancer-y: 0px;
$sprite-lancer-offset-x: -364px;
$sprite-lancer-offset-y: 0px;
$sprite-lancer-width: 88px;
$sprite-lancer-height: 87px;
$sprite-lancer-total-width: 452px;
$sprite-lancer-total-height: 355px;
$sprite-lancer-image: '../img/sprite.png';
$sprite-lancer: (364px, 0px, -364px, 0px, 88px, 87px, 452px, 355px, '../img/sprite.png', 'sprite_lancer', );
$sprite-lancer-hover-name: 'sprite_lancer_hover';
$sprite-lancer-hover-x: 364px;
$sprite-lancer-hover-y: 87px;
$sprite-lancer-hover-offset-x: -364px;
$sprite-lancer-hover-offset-y: -87px;
$sprite-lancer-hover-width: 88px;
$sprite-lancer-hover-height: 87px;
$sprite-lancer-hover-total-width: 452px;
$sprite-lancer-hover-total-height: 355px;
$sprite-lancer-hover-image: '../img/sprite.png';
$sprite-lancer-hover: (364px, 87px, -364px, -87px, 88px, 87px, 452px, 355px, '../img/sprite.png', 'sprite_lancer_hover', );
$sprite-learn-name: 'sprite_learn';
$sprite-learn-x: 118px;
$sprite-learn-y: 279px;
$sprite-learn-offset-x: -118px;
$sprite-learn-offset-y: -279px;
$sprite-learn-width: 73px;
$sprite-learn-height: 60px;
$sprite-learn-total-width: 452px;
$sprite-learn-total-height: 355px;
$sprite-learn-image: '../img/sprite.png';
$sprite-learn: (118px, 279px, -118px, -279px, 73px, 60px, 452px, 355px, '../img/sprite.png', 'sprite_learn', );
$sprite-learn-hover-name: 'sprite_learn_hover';
$sprite-learn-hover-x: 191px;
$sprite-learn-hover-y: 279px;
$sprite-learn-hover-offset-x: -191px;
$sprite-learn-hover-offset-y: -279px;
$sprite-learn-hover-width: 73px;
$sprite-learn-hover-height: 60px;
$sprite-learn-hover-total-width: 452px;
$sprite-learn-hover-total-height: 355px;
$sprite-learn-hover-image: '../img/sprite.png';
$sprite-learn-hover: (191px, 279px, -191px, -279px, 73px, 60px, 452px, 355px, '../img/sprite.png', 'sprite_learn_hover', );
$sprite-picto-name: 'sprite_picto';
$sprite-picto-x: 287px;
$sprite-picto-y: 201px;
$sprite-picto-offset-x: -287px;
$sprite-picto-offset-y: -201px;
$sprite-picto-width: 35px;
$sprite-picto-height: 54px;
$sprite-picto-total-width: 452px;
$sprite-picto-total-height: 355px;
$sprite-picto-image: '../img/sprite.png';
$sprite-picto: (287px, 201px, -287px, -201px, 35px, 54px, 452px, 355px, '../img/sprite.png', 'sprite_picto', );
$sprite-se-financer-name: 'sprite_se-financer';
$sprite-se-financer-x: 0px;
$sprite-se-financer-y: 0px;
$sprite-se-financer-offset-x: 0px;
$sprite-se-financer-offset-y: 0px;
$sprite-se-financer-width: 91px;
$sprite-se-financer-height: 104px;
$sprite-se-financer-total-width: 452px;
$sprite-se-financer-total-height: 355px;
$sprite-se-financer-image: '../img/sprite.png';
$sprite-se-financer: (0px, 0px, 0px, 0px, 91px, 104px, 452px, 355px, '../img/sprite.png', 'sprite_se-financer', );
$sprite-se-financer-hover-name: 'sprite_se-financer_hover';
$sprite-se-financer-hover-x: 91px;
$sprite-se-financer-hover-y: 0px;
$sprite-se-financer-hover-offset-x: -91px;
$sprite-se-financer-hover-offset-y: 0px;
$sprite-se-financer-hover-width: 91px;
$sprite-se-financer-hover-height: 104px;
$sprite-se-financer-hover-total-width: 452px;
$sprite-se-financer-hover-total-height: 355px;
$sprite-se-financer-hover-image: '../img/sprite.png';
$sprite-se-financer-hover: (91px, 0px, -91px, 0px, 91px, 104px, 452px, 355px, '../img/sprite.png', 'sprite_se-financer_hover', );
$sprite-team-name: 'sprite_team';
$sprite-team-x: 364px;
$sprite-team-y: 231px;
$sprite-team-offset-x: -364px;
$sprite-team-offset-y: -231px;
$sprite-team-width: 26px;
$sprite-team-height: 26px;
$sprite-team-total-width: 452px;
$sprite-team-total-height: 355px;
$sprite-team-image: '../img/sprite.png';
$sprite-team: (364px, 231px, -364px, -231px, 26px, 26px, 452px, 355px, '../img/sprite.png', 'sprite_team', );
$sprite-tree-name: 'sprite_tree';
$sprite-tree-x: 0px;
$sprite-tree-y: 279px;
$sprite-tree-offset-x: 0px;
$sprite-tree-offset-y: -279px;
$sprite-tree-width: 59px;
$sprite-tree-height: 76px;
$sprite-tree-total-width: 452px;
$sprite-tree-total-height: 355px;
$sprite-tree-image: '../img/sprite.png';
$sprite-tree: (0px, 279px, 0px, -279px, 59px, 76px, 452px, 355px, '../img/sprite.png', 'sprite_tree', );
$sprite-tree-hover-name: 'sprite_tree_hover';
$sprite-tree-hover-x: 59px;
$sprite-tree-hover-y: 279px;
$sprite-tree-hover-offset-x: -59px;
$sprite-tree-hover-offset-y: -279px;
$sprite-tree-hover-width: 59px;
$sprite-tree-hover-height: 76px;
$sprite-tree-hover-total-width: 452px;
$sprite-tree-hover-total-height: 355px;
$sprite-tree-hover-image: '../img/sprite.png';
$sprite-tree-hover: (59px, 279px, -59px, -279px, 59px, 76px, 452px, 355px, '../img/sprite.png', 'sprite_tree_hover', );
$sprite-user-name: 'sprite_user';
$sprite-user-x: 388px;
$sprite-user-y: 257px;
$sprite-user-offset-x: -388px;
$sprite-user-offset-y: -257px;
$sprite-user-width: 18px;
$sprite-user-height: 21px;
$sprite-user-total-width: 452px;
$sprite-user-total-height: 355px;
$sprite-user-image: '../img/sprite.png';
$sprite-user: (388px, 257px, -388px, -257px, 18px, 21px, 452px, 355px, '../img/sprite.png', 'sprite_user', );
$sprite-useradd-name: 'sprite_useradd';
$sprite-useradd-x: 364px;
$sprite-useradd-y: 257px;
$sprite-useradd-offset-x: -364px;
$sprite-useradd-offset-y: -257px;
$sprite-useradd-width: 24px;
$sprite-useradd-height: 21px;
$sprite-useradd-total-width: 452px;
$sprite-useradd-total-height: 355px;
$sprite-useradd-image: '../img/sprite.png';
$sprite-useradd: (364px, 257px, -364px, -257px, 24px, 21px, 452px, 355px, '../img/sprite.png', 'sprite_useradd', );
$sprite-vie-association-name: 'sprite_vie-association';
$sprite-vie-association-x: 273px;
$sprite-vie-association-y: 0px;
$sprite-vie-association-offset-x: -273px;
$sprite-vie-association-offset-y: 0px;
$sprite-vie-association-width: 91px;
$sprite-vie-association-height: 91px;
$sprite-vie-association-total-width: 452px;
$sprite-vie-association-total-height: 355px;
$sprite-vie-association-image: '../img/sprite.png';
$sprite-vie-association: (273px, 0px, -273px, 0px, 91px, 91px, 452px, 355px, '../img/sprite.png', 'sprite_vie-association', );
$sprite-vie-association-hover-name: 'sprite_vie-association_hover';
$sprite-vie-association-hover-x: 273px;
$sprite-vie-association-hover-y: 91px;
$sprite-vie-association-hover-offset-x: -273px;
$sprite-vie-association-hover-offset-y: -91px;
$sprite-vie-association-hover-width: 91px;
$sprite-vie-association-hover-height: 91px;
$sprite-vie-association-hover-total-width: 452px;
$sprite-vie-association-hover-total-height: 355px;
$sprite-vie-association-hover-image: '../img/sprite.png';
$sprite-vie-association-hover: (273px, 91px, -273px, -91px, 91px, 91px, 452px, 355px, '../img/sprite.png', 'sprite_vie-association_hover', );
$sprite-f-r-2x-name: 'sprite_FR-2x';
$sprite-f-r-2x-x: 780px;
$sprite-f-r-2x-y: 462px;
$sprite-f-r-2x-offset-x: -780px;
$sprite-f-r-2x-offset-y: -462px;
$sprite-f-r-2x-width: 56px;
$sprite-f-r-2x-height: 40px;
$sprite-f-r-2x-total-width: 904px;
$sprite-f-r-2x-total-height: 710px;
$sprite-f-r-2x-image: '../img/sprite-2x.png';
$sprite-f-r-2x: (780px, 462px, -780px, -462px, 56px, 40px, 904px, 710px, '../img/sprite-2x.png', 'sprite_FR-2x', );
$sprite-animer-sa-marque-2x-name: 'sprite_animer-sa-marque-2x';
$sprite-animer-sa-marque-2x-x: 182px;
$sprite-animer-sa-marque-2x-y: 208px;
$sprite-animer-sa-marque-2x-offset-x: -182px;
$sprite-animer-sa-marque-2x-offset-y: -208px;
$sprite-animer-sa-marque-2x-width: 182px;
$sprite-animer-sa-marque-2x-height: 182px;
$sprite-animer-sa-marque-2x-total-width: 904px;
$sprite-animer-sa-marque-2x-total-height: 710px;
$sprite-animer-sa-marque-2x-image: '../img/sprite-2x.png';
$sprite-animer-sa-marque-2x: (182px, 208px, -182px, -208px, 182px, 182px, 904px, 710px, '../img/sprite-2x.png', 'sprite_animer-sa-marque-2x', );
$sprite-animer-sa-marque-hover-2x-name: 'sprite_animer-sa-marque_hover-2x';
$sprite-animer-sa-marque-hover-2x-x: 364px;
$sprite-animer-sa-marque-hover-2x-y: 208px;
$sprite-animer-sa-marque-hover-2x-offset-x: -364px;
$sprite-animer-sa-marque-hover-2x-offset-y: -208px;
$sprite-animer-sa-marque-hover-2x-width: 182px;
$sprite-animer-sa-marque-hover-2x-height: 182px;
$sprite-animer-sa-marque-hover-2x-total-width: 904px;
$sprite-animer-sa-marque-hover-2x-total-height: 710px;
$sprite-animer-sa-marque-hover-2x-image: '../img/sprite-2x.png';
$sprite-animer-sa-marque-hover-2x: (364px, 208px, -364px, -208px, 182px, 182px, 904px, 710px, '../img/sprite-2x.png', 'sprite_animer-sa-marque_hover-2x', );
$sprite-approve-invoice-2x-name: 'sprite_approve-invoice-2x';
$sprite-approve-invoice-2x-x: 644px;
$sprite-approve-invoice-2x-y: 402px;
$sprite-approve-invoice-2x-offset-x: -644px;
$sprite-approve-invoice-2x-offset-y: -402px;
$sprite-approve-invoice-2x-width: 44px;
$sprite-approve-invoice-2x-height: 50px;
$sprite-approve-invoice-2x-total-width: 904px;
$sprite-approve-invoice-2x-total-height: 710px;
$sprite-approve-invoice-2x-image: '../img/sprite-2x.png';
$sprite-approve-invoice-2x: (644px, 402px, -644px, -402px, 44px, 50px, 904px, 710px, '../img/sprite-2x.png', 'sprite_approve-invoice-2x', );
$sprite-commercialiser-2x-name: 'sprite_commercialiser-2x';
$sprite-commercialiser-2x-x: 728px;
$sprite-commercialiser-2x-y: 348px;
$sprite-commercialiser-2x-offset-x: -728px;
$sprite-commercialiser-2x-offset-y: -348px;
$sprite-commercialiser-2x-width: 174px;
$sprite-commercialiser-2x-height: 114px;
$sprite-commercialiser-2x-total-width: 904px;
$sprite-commercialiser-2x-total-height: 710px;
$sprite-commercialiser-2x-image: '../img/sprite-2x.png';
$sprite-commercialiser-2x: (728px, 348px, -728px, -348px, 174px, 114px, 904px, 710px, '../img/sprite-2x.png', 'sprite_commercialiser-2x', );
$sprite-commercialiser-hover-2x-name: 'sprite_commercialiser_hover-2x';
$sprite-commercialiser-hover-2x-x: 400px;
$sprite-commercialiser-hover-2x-y: 402px;
$sprite-commercialiser-hover-2x-offset-x: -400px;
$sprite-commercialiser-hover-2x-offset-y: -402px;
$sprite-commercialiser-hover-2x-width: 174px;
$sprite-commercialiser-hover-2x-height: 114px;
$sprite-commercialiser-hover-2x-total-width: 904px;
$sprite-commercialiser-hover-2x-total-height: 710px;
$sprite-commercialiser-hover-2x-image: '../img/sprite-2x.png';
$sprite-commercialiser-hover-2x: (400px, 402px, -400px, -402px, 174px, 114px, 904px, 710px, '../img/sprite-2x.png', 'sprite_commercialiser_hover-2x', );
$sprite-export-2x-name: 'sprite_export-2x';
$sprite-export-2x-x: 0px;
$sprite-export-2x-y: 402px;
$sprite-export-2x-offset-x: 0px;
$sprite-export-2x-offset-y: -402px;
$sprite-export-2x-width: 200px;
$sprite-export-2x-height: 156px;
$sprite-export-2x-total-width: 904px;
$sprite-export-2x-total-height: 710px;
$sprite-export-2x-image: '../img/sprite-2x.png';
$sprite-export-2x: (0px, 402px, 0px, -402px, 200px, 156px, 904px, 710px, '../img/sprite-2x.png', 'sprite_export-2x', );
$sprite-export-hover-2x-name: 'sprite_export_hover-2x';
$sprite-export-hover-2x-x: 200px;
$sprite-export-hover-2x-y: 402px;
$sprite-export-hover-2x-offset-x: -200px;
$sprite-export-hover-2x-offset-y: -402px;
$sprite-export-hover-2x-width: 200px;
$sprite-export-hover-2x-height: 156px;
$sprite-export-hover-2x-total-width: 904px;
$sprite-export-hover-2x-total-height: 710px;
$sprite-export-hover-2x-image: '../img/sprite-2x.png';
$sprite-export-hover-2x: (200px, 402px, -200px, -402px, 200px, 156px, 904px, 710px, '../img/sprite-2x.png', 'sprite_export_hover-2x', );
$sprite-innover-2x-name: 'sprite_innover-2x';
$sprite-innover-2x-x: 364px;
$sprite-innover-2x-y: 0px;
$sprite-innover-2x-offset-x: -364px;
$sprite-innover-2x-offset-y: 0px;
$sprite-innover-2x-width: 182px;
$sprite-innover-2x-height: 194px;
$sprite-innover-2x-total-width: 904px;
$sprite-innover-2x-total-height: 710px;
$sprite-innover-2x-image: '../img/sprite-2x.png';
$sprite-innover-2x: (364px, 0px, -364px, 0px, 182px, 194px, 904px, 710px, '../img/sprite-2x.png', 'sprite_innover-2x', );
$sprite-innover-hover-2x-name: 'sprite_innover_hover-2x';
$sprite-innover-hover-2x-x: 0px;
$sprite-innover-hover-2x-y: 208px;
$sprite-innover-hover-2x-offset-x: 0px;
$sprite-innover-hover-2x-offset-y: -208px;
$sprite-innover-hover-2x-width: 182px;
$sprite-innover-hover-2x-height: 194px;
$sprite-innover-hover-2x-total-width: 904px;
$sprite-innover-hover-2x-total-height: 710px;
$sprite-innover-hover-2x-image: '../img/sprite-2x.png';
$sprite-innover-hover-2x: (0px, 208px, 0px, -208px, 182px, 194px, 904px, 710px, '../img/sprite-2x.png', 'sprite_innover_hover-2x', );
$sprite-lancer-2x-name: 'sprite_lancer-2x';
$sprite-lancer-2x-x: 728px;
$sprite-lancer-2x-y: 0px;
$sprite-lancer-2x-offset-x: -728px;
$sprite-lancer-2x-offset-y: 0px;
$sprite-lancer-2x-width: 176px;
$sprite-lancer-2x-height: 174px;
$sprite-lancer-2x-total-width: 904px;
$sprite-lancer-2x-total-height: 710px;
$sprite-lancer-2x-image: '../img/sprite-2x.png';
$sprite-lancer-2x: (728px, 0px, -728px, 0px, 176px, 174px, 904px, 710px, '../img/sprite-2x.png', 'sprite_lancer-2x', );
$sprite-lancer-hover-2x-name: 'sprite_lancer_hover-2x';
$sprite-lancer-hover-2x-x: 728px;
$sprite-lancer-hover-2x-y: 174px;
$sprite-lancer-hover-2x-offset-x: -728px;
$sprite-lancer-hover-2x-offset-y: -174px;
$sprite-lancer-hover-2x-width: 176px;
$sprite-lancer-hover-2x-height: 174px;
$sprite-lancer-hover-2x-total-width: 904px;
$sprite-lancer-hover-2x-total-height: 710px;
$sprite-lancer-hover-2x-image: '../img/sprite-2x.png';
$sprite-lancer-hover-2x: (728px, 174px, -728px, -174px, 176px, 174px, 904px, 710px, '../img/sprite-2x.png', 'sprite_lancer_hover-2x', );
$sprite-learn-2x-name: 'sprite_learn-2x';
$sprite-learn-2x-x: 236px;
$sprite-learn-2x-y: 558px;
$sprite-learn-2x-offset-x: -236px;
$sprite-learn-2x-offset-y: -558px;
$sprite-learn-2x-width: 146px;
$sprite-learn-2x-height: 120px;
$sprite-learn-2x-total-width: 904px;
$sprite-learn-2x-total-height: 710px;
$sprite-learn-2x-image: '../img/sprite-2x.png';
$sprite-learn-2x: (236px, 558px, -236px, -558px, 146px, 120px, 904px, 710px, '../img/sprite-2x.png', 'sprite_learn-2x', );
$sprite-learn-hover-2x-name: 'sprite_learn_hover-2x';
$sprite-learn-hover-2x-x: 382px;
$sprite-learn-hover-2x-y: 558px;
$sprite-learn-hover-2x-offset-x: -382px;
$sprite-learn-hover-2x-offset-y: -558px;
$sprite-learn-hover-2x-width: 146px;
$sprite-learn-hover-2x-height: 120px;
$sprite-learn-hover-2x-total-width: 904px;
$sprite-learn-hover-2x-total-height: 710px;
$sprite-learn-hover-2x-image: '../img/sprite-2x.png';
$sprite-learn-hover-2x: (382px, 558px, -382px, -558px, 146px, 120px, 904px, 710px, '../img/sprite-2x.png', 'sprite_learn_hover-2x', );
$sprite-picto-2x-name: 'sprite_picto-2x';
$sprite-picto-2x-x: 574px;
$sprite-picto-2x-y: 402px;
$sprite-picto-2x-offset-x: -574px;
$sprite-picto-2x-offset-y: -402px;
$sprite-picto-2x-width: 70px;
$sprite-picto-2x-height: 108px;
$sprite-picto-2x-total-width: 904px;
$sprite-picto-2x-total-height: 710px;
$sprite-picto-2x-image: '../img/sprite-2x.png';
$sprite-picto-2x: (574px, 402px, -574px, -402px, 70px, 108px, 904px, 710px, '../img/sprite-2x.png', 'sprite_picto-2x', );
$sprite-se-financer-2x-name: 'sprite_se-financer-2x';
$sprite-se-financer-2x-x: 0px;
$sprite-se-financer-2x-y: 0px;
$sprite-se-financer-2x-offset-x: 0px;
$sprite-se-financer-2x-offset-y: 0px;
$sprite-se-financer-2x-width: 182px;
$sprite-se-financer-2x-height: 208px;
$sprite-se-financer-2x-total-width: 904px;
$sprite-se-financer-2x-total-height: 710px;
$sprite-se-financer-2x-image: '../img/sprite-2x.png';
$sprite-se-financer-2x: (0px, 0px, 0px, 0px, 182px, 208px, 904px, 710px, '../img/sprite-2x.png', 'sprite_se-financer-2x', );
$sprite-se-financer-hover-2x-name: 'sprite_se-financer_hover-2x';
$sprite-se-financer-hover-2x-x: 182px;
$sprite-se-financer-hover-2x-y: 0px;
$sprite-se-financer-hover-2x-offset-x: -182px;
$sprite-se-financer-hover-2x-offset-y: 0px;
$sprite-se-financer-hover-2x-width: 182px;
$sprite-se-financer-hover-2x-height: 208px;
$sprite-se-financer-hover-2x-total-width: 904px;
$sprite-se-financer-hover-2x-total-height: 710px;
$sprite-se-financer-hover-2x-image: '../img/sprite-2x.png';
$sprite-se-financer-hover-2x: (182px, 0px, -182px, 0px, 182px, 208px, 904px, 710px, '../img/sprite-2x.png', 'sprite_se-financer_hover-2x', );
$sprite-team-2x-name: 'sprite_team-2x';
$sprite-team-2x-x: 728px;
$sprite-team-2x-y: 462px;
$sprite-team-2x-offset-x: -728px;
$sprite-team-2x-offset-y: -462px;
$sprite-team-2x-width: 52px;
$sprite-team-2x-height: 52px;
$sprite-team-2x-total-width: 904px;
$sprite-team-2x-total-height: 710px;
$sprite-team-2x-image: '../img/sprite-2x.png';
$sprite-team-2x: (728px, 462px, -728px, -462px, 52px, 52px, 904px, 710px, '../img/sprite-2x.png', 'sprite_team-2x', );
$sprite-tree-2x-name: 'sprite_tree-2x';
$sprite-tree-2x-x: 0px;
$sprite-tree-2x-y: 558px;
$sprite-tree-2x-offset-x: 0px;
$sprite-tree-2x-offset-y: -558px;
$sprite-tree-2x-width: 118px;
$sprite-tree-2x-height: 152px;
$sprite-tree-2x-total-width: 904px;
$sprite-tree-2x-total-height: 710px;
$sprite-tree-2x-image: '../img/sprite-2x.png';
$sprite-tree-2x: (0px, 558px, 0px, -558px, 118px, 152px, 904px, 710px, '../img/sprite-2x.png', 'sprite_tree-2x', );
$sprite-tree-hover-2x-name: 'sprite_tree_hover-2x';
$sprite-tree-hover-2x-x: 118px;
$sprite-tree-hover-2x-y: 558px;
$sprite-tree-hover-2x-offset-x: -118px;
$sprite-tree-hover-2x-offset-y: -558px;
$sprite-tree-hover-2x-width: 118px;
$sprite-tree-hover-2x-height: 152px;
$sprite-tree-hover-2x-total-width: 904px;
$sprite-tree-hover-2x-total-height: 710px;
$sprite-tree-hover-2x-image: '../img/sprite-2x.png';
$sprite-tree-hover-2x: (118px, 558px, -118px, -558px, 118px, 152px, 904px, 710px, '../img/sprite-2x.png', 'sprite_tree_hover-2x', );
$sprite-user-2x-name: 'sprite_user-2x';
$sprite-user-2x-x: 776px;
$sprite-user-2x-y: 514px;
$sprite-user-2x-offset-x: -776px;
$sprite-user-2x-offset-y: -514px;
$sprite-user-2x-width: 36px;
$sprite-user-2x-height: 42px;
$sprite-user-2x-total-width: 904px;
$sprite-user-2x-total-height: 710px;
$sprite-user-2x-image: '../img/sprite-2x.png';
$sprite-user-2x: (776px, 514px, -776px, -514px, 36px, 42px, 904px, 710px, '../img/sprite-2x.png', 'sprite_user-2x', );
$sprite-useradd-2x-name: 'sprite_useradd-2x';
$sprite-useradd-2x-x: 728px;
$sprite-useradd-2x-y: 514px;
$sprite-useradd-2x-offset-x: -728px;
$sprite-useradd-2x-offset-y: -514px;
$sprite-useradd-2x-width: 48px;
$sprite-useradd-2x-height: 42px;
$sprite-useradd-2x-total-width: 904px;
$sprite-useradd-2x-total-height: 710px;
$sprite-useradd-2x-image: '../img/sprite-2x.png';
$sprite-useradd-2x: (728px, 514px, -728px, -514px, 48px, 42px, 904px, 710px, '../img/sprite-2x.png', 'sprite_useradd-2x', );
$sprite-vie-association-2x-name: 'sprite_vie-association-2x';
$sprite-vie-association-2x-x: 546px;
$sprite-vie-association-2x-y: 0px;
$sprite-vie-association-2x-offset-x: -546px;
$sprite-vie-association-2x-offset-y: 0px;
$sprite-vie-association-2x-width: 182px;
$sprite-vie-association-2x-height: 182px;
$sprite-vie-association-2x-total-width: 904px;
$sprite-vie-association-2x-total-height: 710px;
$sprite-vie-association-2x-image: '../img/sprite-2x.png';
$sprite-vie-association-2x: (546px, 0px, -546px, 0px, 182px, 182px, 904px, 710px, '../img/sprite-2x.png', 'sprite_vie-association-2x', );
$sprite-vie-association-hover-2x-name: 'sprite_vie-association_hover-2x';
$sprite-vie-association-hover-2x-x: 546px;
$sprite-vie-association-hover-2x-y: 182px;
$sprite-vie-association-hover-2x-offset-x: -546px;
$sprite-vie-association-hover-2x-offset-y: -182px;
$sprite-vie-association-hover-2x-width: 182px;
$sprite-vie-association-hover-2x-height: 182px;
$sprite-vie-association-hover-2x-total-width: 904px;
$sprite-vie-association-hover-2x-total-height: 710px;
$sprite-vie-association-hover-2x-image: '../img/sprite-2x.png';
$sprite-vie-association-hover-2x: (546px, 182px, -546px, -182px, 182px, 182px, 904px, 710px, '../img/sprite-2x.png', 'sprite_vie-association_hover-2x', );
$spritesheet-width: 452px;
$spritesheet-height: 355px;
$spritesheet-image: '../img/sprite.png';
$spritesheet-sprites: ($sprite-f-r, $sprite-animer-sa-marque, $sprite-animer-sa-marque-hover, $sprite-approve-invoice, $sprite-commercialiser, $sprite-commercialiser-hover, $sprite-export, $sprite-export-hover, $sprite-innover, $sprite-innover-hover, $sprite-lancer, $sprite-lancer-hover, $sprite-learn, $sprite-learn-hover, $sprite-picto, $sprite-se-financer, $sprite-se-financer-hover, $sprite-team, $sprite-tree, $sprite-tree-hover, $sprite-user, $sprite-useradd, $sprite-vie-association, $sprite-vie-association-hover, );
$spritesheet: (452px, 355px, '../img/sprite.png', $spritesheet-sprites, );
$retina-spritesheet-width: 904px;
$retina-spritesheet-height: 710px;
$retina-spritesheet-image: '../img/sprite-2x.png';
$retina-spritesheet-sprites: ($sprite-f-r-2x, $sprite-animer-sa-marque-2x, $sprite-animer-sa-marque-hover-2x, $sprite-approve-invoice-2x, $sprite-commercialiser-2x, $sprite-commercialiser-hover-2x, $sprite-export-2x, $sprite-export-hover-2x, $sprite-innover-2x, $sprite-innover-hover-2x, $sprite-lancer-2x, $sprite-lancer-hover-2x, $sprite-learn-2x, $sprite-learn-hover-2x, $sprite-picto-2x, $sprite-se-financer-2x, $sprite-se-financer-hover-2x, $sprite-team-2x, $sprite-tree-2x, $sprite-tree-hover-2x, $sprite-user-2x, $sprite-useradd-2x, $sprite-vie-association-2x, $sprite-vie-association-hover-2x, );
$retina-spritesheet: (904px, 710px, '../img/sprite-2x.png', $retina-spritesheet-sprites, );

// These "retina group" variables are mappings for the naming and pairing of normal and retina sprites.
//
// The list formatted variables are intended for mixins like `retina-sprite` and `retina-sprites`.
$sprite-f-r-group-name: 'sprite_FR';
$sprite-f-r-group: ('sprite_FR', $sprite-f-r, $sprite-f-r-2x, );
$sprite-animer-sa-marque-group-name: 'sprite_animer-sa-marque';
$sprite-animer-sa-marque-group: ('sprite_animer-sa-marque', $sprite-animer-sa-marque, $sprite-animer-sa-marque-2x, );
$sprite-animer-sa-marque-hover-group-name: 'sprite_animer-sa-marque_hover';
$sprite-animer-sa-marque-hover-group: ('sprite_animer-sa-marque_hover', $sprite-animer-sa-marque-hover, $sprite-animer-sa-marque-hover-2x, );
$sprite-approve-invoice-group-name: 'sprite_approve-invoice';
$sprite-approve-invoice-group: ('sprite_approve-invoice', $sprite-approve-invoice, $sprite-approve-invoice-2x, );
$sprite-commercialiser-group-name: 'sprite_commercialiser';
$sprite-commercialiser-group: ('sprite_commercialiser', $sprite-commercialiser, $sprite-commercialiser-2x, );
$sprite-commercialiser-hover-group-name: 'sprite_commercialiser_hover';
$sprite-commercialiser-hover-group: ('sprite_commercialiser_hover', $sprite-commercialiser-hover, $sprite-commercialiser-hover-2x, );
$sprite-export-group-name: 'sprite_export';
$sprite-export-group: ('sprite_export', $sprite-export, $sprite-export-2x, );
$sprite-export-hover-group-name: 'sprite_export_hover';
$sprite-export-hover-group: ('sprite_export_hover', $sprite-export-hover, $sprite-export-hover-2x, );
$sprite-innover-group-name: 'sprite_innover';
$sprite-innover-group: ('sprite_innover', $sprite-innover, $sprite-innover-2x, );
$sprite-innover-hover-group-name: 'sprite_innover_hover';
$sprite-innover-hover-group: ('sprite_innover_hover', $sprite-innover-hover, $sprite-innover-hover-2x, );
$sprite-lancer-group-name: 'sprite_lancer';
$sprite-lancer-group: ('sprite_lancer', $sprite-lancer, $sprite-lancer-2x, );
$sprite-lancer-hover-group-name: 'sprite_lancer_hover';
$sprite-lancer-hover-group: ('sprite_lancer_hover', $sprite-lancer-hover, $sprite-lancer-hover-2x, );
$sprite-learn-group-name: 'sprite_learn';
$sprite-learn-group: ('sprite_learn', $sprite-learn, $sprite-learn-2x, );
$sprite-learn-hover-group-name: 'sprite_learn_hover';
$sprite-learn-hover-group: ('sprite_learn_hover', $sprite-learn-hover, $sprite-learn-hover-2x, );
$sprite-picto-group-name: 'sprite_picto';
$sprite-picto-group: ('sprite_picto', $sprite-picto, $sprite-picto-2x, );
$sprite-se-financer-group-name: 'sprite_se-financer';
$sprite-se-financer-group: ('sprite_se-financer', $sprite-se-financer, $sprite-se-financer-2x, );
$sprite-se-financer-hover-group-name: 'sprite_se-financer_hover';
$sprite-se-financer-hover-group: ('sprite_se-financer_hover', $sprite-se-financer-hover, $sprite-se-financer-hover-2x, );
$sprite-team-group-name: 'sprite_team';
$sprite-team-group: ('sprite_team', $sprite-team, $sprite-team-2x, );
$sprite-tree-group-name: 'sprite_tree';
$sprite-tree-group: ('sprite_tree', $sprite-tree, $sprite-tree-2x, );
$sprite-tree-hover-group-name: 'sprite_tree_hover';
$sprite-tree-hover-group: ('sprite_tree_hover', $sprite-tree-hover, $sprite-tree-hover-2x, );
$sprite-user-group-name: 'sprite_user';
$sprite-user-group: ('sprite_user', $sprite-user, $sprite-user-2x, );
$sprite-useradd-group-name: 'sprite_useradd';
$sprite-useradd-group: ('sprite_useradd', $sprite-useradd, $sprite-useradd-2x, );
$sprite-vie-association-group-name: 'sprite_vie-association';
$sprite-vie-association-group: ('sprite_vie-association', $sprite-vie-association, $sprite-vie-association-2x, );
$sprite-vie-association-hover-group-name: 'sprite_vie-association_hover';
$sprite-vie-association-hover-group: ('sprite_vie-association_hover', $sprite-vie-association-hover, $sprite-vie-association-hover-2x, );
$retina-groups: ($sprite-f-r-group, $sprite-animer-sa-marque-group, $sprite-animer-sa-marque-hover-group, $sprite-approve-invoice-group, $sprite-commercialiser-group, $sprite-commercialiser-hover-group, $sprite-export-group, $sprite-export-hover-group, $sprite-innover-group, $sprite-innover-hover-group, $sprite-lancer-group, $sprite-lancer-hover-group, $sprite-learn-group, $sprite-learn-hover-group, $sprite-picto-group, $sprite-se-financer-group, $sprite-se-financer-hover-group, $sprite-team-group, $sprite-tree-group, $sprite-tree-hover-group, $sprite-user-group, $sprite-useradd-group, $sprite-vie-association-group, $sprite-vie-association-hover-group, );

// The provided mixins are intended to be used with the array-like variables
//
// .icon-home {
//   @include sprite-width($icon-home);
// }
//
// .icon-email {
//   @include sprite($icon-email);
// }
//
// Example usage in HTML:
//
// `display: block` sprite:
// <div class="icon-home"></div>
//
// To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:
//
// // CSS
// .icon {
//   display: inline-block;
// }
//
// // HTML
// <i class="icon icon-home"></i>
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

// The `retina-sprite` mixin sets up rules and a media query for a sprite/retina sprite.
//   It should be used with a "retina group" variable.
//
// The media query is from CSS Tricks: https://css-tricks.com/snippets/css/retina-display-media-query/
//
// $icon-home-group: ('icon-home', $icon-home, $icon-home-2x, );
//
// .icon-home {
//   @include retina-sprite($icon-home-group);
// }
@mixin sprite-background-size($sprite) {
  $sprite-total-width: nth($sprite, 7);
  $sprite-total-height: nth($sprite, 8);
  background-size: $sprite-total-width $sprite-total-height;
}

@mixin retina-sprite($retina-group) {
  $normal-sprite: nth($retina-group, 2);
  $retina-sprite: nth($retina-group, 3);
  @include sprite($normal-sprite);

  @media (-webkit-min-device-pixel-ratio: 2),
  (min-resolution: 192dpi) {
    @include sprite-image($retina-sprite);
    @include sprite-background-size($normal-sprite);
  }
}

// The `sprites` mixin generates identical output to the CSS template
//   but can be overridden inside of SCSS
//
// @include sprites($spritesheet-sprites);
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}

// The `retina-sprites` mixin generates a CSS rule and media query for retina groups
//   This yields the same output as CSS retina template but can be overridden in SCSS
//
// @include retina-sprites($retina-groups);
@mixin retina-sprites($retina-groups) {
  @each $retina-group in $retina-groups {
    $sprite-name: nth($retina-group, 1);
    .#{$sprite-name} {
      @include retina-sprite($retina-group);
    }
  }
}
