.news__content{
  @include make-row();
}

.article__body{
  @include make-col-ready();

  @include media-breakpoint-down(md) {
    @include make-col(12);
  }
  @include media-breakpoint-up(md) {
    @include make-col(9);
  }

}
.article__sidbar{
  @include make-col-ready();

  @include media-breakpoint-down(md) {
    @include make-col(12);
  }
  @include media-breakpoint-up(md) {
    @include make-col(3);
  }
  .wrapper{
    background: #eee;
    padding: 20px;
    .date-range{
      font-family:$montserrat-bold;
      font-size: 1.6rem;
      text-transform: uppercase;
    }
  }
}

#sidebar{
  position: absolute;
}


.date-range {
  font-size: 1rem;

  .date-range-on,
  .date-range-to,
  .date-range-from,
  .date-range-start,
  .date-range-end {
    &:after {
      content: " ";
    }
  }
}

#id_archive {
  .side-bar {
    border-left: 1px solid #eee;

    .years {
      >li {
        a {
          font-weight: bold;
          color: theme-color('text-color');
        }

      }

      ul {
        li {
          position: relative;
          padding: .75rem 1.25rem;
          padding-left: 30px;

          &:nth-child(odd) {
            background-color: darken($white, 2);
          }

          &.month-active,
          &.year-active {
            a {
              color: theme-color('text-color');
            }

            &::before {
              font-family: 'Font Awesome 5 Free';
              font-weight: 900;
              content: "\f00c";
              font-size: 10px;
              display: flex;
              align-items: center;
              justify-content: center;
              color: #88bd30;
              height: 16px;
              width: 16px;
              border-radius: 16px;
              position: absolute;
              background-color: rgba(136, 189, 48, .3);
              top: 50%;
              margin-top: -8px;
              left: 10px;
            }
          }
        }
      }
    }
  }
}

.category-filter {
  @extend .list-group;
  @extend .list-group-flush;

  .list-group-item {
    border: 0;
    padding-left: 30px;
    background-color: white;

    &:nth-child(odd) {
      background-color: darken($white, 2);
    }

    &.active {
      &::before {
        font-family: 'Font Awesome 5 Free';
        font-weight: 900;
        content: "\f00c";
        font-size: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #88bd30;
        height: 16px;
        width: 16px;
        border-radius: 16px;
        position: absolute;
        background-color: rgba(136, 189, 48, .3);
        top: 50%;
        margin-top: -8px;
        left: 10px;
      }

      a {
        color: theme-color('text-color');
      }
    }
  }
}

.social-share-buttons {
  display: inline;
  margin-left: 1em;

  .facebook-share-button {
    color: theme-color('facebook');
  }

  .twitter-share-button {
    color: theme-color('twitter');
  }

  .googleplus-share-button {
    color: theme-color('googleplus');
  }

  .linkedin-share-button {
    color: theme-color('linkedin');
  }

  .email-share-button {
    color: theme-color('dark-gray');
  }
}
