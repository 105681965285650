.brochure-row {
  @include make-row();
  margin-bottom: 2rem;

  img {
    height: auto;
    max-width: 100%;
    width: 100%;
  }
  .brochure-item{
    @include make-col-ready();

    @include media-breakpoint-up(md) {
      @include make-col(4);
    }
    @include media-breakpoint-up(lg) {
      @include make-col(3);
    }
  }
}

.brochure-item {

  position: relative;

  &__content{
    height: calc(100% - 2.2rem);
    margin-bottom: 2.2rem;
  }

  &:hover{
    .brochure-informations{
      background: rgba($black, 1);
    }
  }
}

.brochure-caption {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.brochure-informations {
  background: rgba($black, .8);
  bottom: 1px;
  left: 16px;
  padding: 10px;
  position: absolute;
  right: 16px;
  text-align: center;
  transition: background .3s ease;
  color: $white;
}

.brochure-link {
  display: block;
  margin-bottom: 10px;
}

.brochure-cover {
  border: 1px solid #eee;
  display: block;
  overflow: hidden;
}
.brochure-description{
  &:empty{
    display: none;
  }
}
