@include media-breakpoint-up(lg) {
  #id_subscribers {
    .header__site.header-scroll {
      position: static;
    }
  }
}

.user__icon {
  background-image: url("../img/user.svg");
  display: inline-block;
  height: 45px;
  width: 45px;
  background-size: cover;
  margin-right: 1rem;
}
.header__pro {
  .user__img {
    img {
      border-radius: 50%;
      width: 50px;
      height: auto;
    }
  }
}

@keyframes stickAnim {
  0% {
    transform: translateY(-200px);
  }

  100% {
    transform: translateY(0);
  }
}

$cubic: cubic-bezier(0.4, 0.01, 0.165, 0.99);

@include media-breakpoint-down(md) {
}

#id_subscribers {
  // .header__site.header-scroll{
  //     position: static;
  //     animation: none;
  // }
  .scroll-margin {
    margin-top: 0;
  }
}

.main__header {
  background-color: white;
  border-bottom: 1px solid #f3f3f3;
  padding: 10px;
  display: flex;
  align-items: center;
  position: sticky;
  z-index: 99999;
  top: 0;
  nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1;
  }
  a {
    color: theme-color("dark");
    &:hover {
      color: theme-color("primary");
    }
  }
  .extra_link {
    color: theme-color("secondary");
    position: relative;
    font-family: $montserrat-bold;
    padding-left: 0.3rem;
    padding-right: 0.3rem;
    display: block;
    @include media-breakpoint-up(lg) {
      margin-right: 0.5rem;
    }
    span {
      position: relative;
    }
    &::before {
      content: "";
      position: absolute;
      display: block;
      bottom: 6px;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
      height: 5px;
      background-color: rgba(theme-color("secondary"), 0.2);
    }
  }

  .logo {
    display: block;
    padding-top: 10px;
    padding-bottom: 10px;
    width: 200px;
    position: relative;
    img {
      max-width: 100%;
      height: auto;
    }
  }
  .p-relative{
    position: relative;
  }
  .en_version{
    background-color: white;
    position: absolute;
    display: inline-flex;
    align-items: center;
    padding: .6rem;
    left: 30px;
    bottom: -30px;
    font-weight: bold;
    @include media-breakpoint-down(sm) {
      left: 0;
      background-color: transparent;
    }
    i.flag_en{
      display: inline-block;
      width: 29px;
      height: 17px;
      margin-right: 0.4rem;
      background-image: url(../img/en.svg);
      background-size: contain;
      background-repeat: no-repeat;
    }
  }
  .nav__site {
    display: flex;
    list-style: none;
    padding-left: 0;
    gap: 0.5rem;
    margin-left: 1rem;
    margin-bottom: 0;

    > li {
      position: relative;
      > a {
        font-family: $montserrat-bold;
        font-size: 16px;
      }
      &.ancestor,
      &.active {
        a span {
          color: theme-color("primary");
        }
      }
    }
  }
  .right__nav {
    margin-left: auto;
    display: flex;
    gap: 0.5rem;
    align-items: center;
  }
  .btn.btn-primary {
    &.light {
      background-color: rgba(theme-color("primary"), 0.2);
      color: theme-color("primary") !important;
      border-color: rgba(theme-color("primary"), 0.2);
      box-shadow: unset;
    }
  }
  .dropdown-menu {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border: 0;
    padding: 1rem;
    display: block;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    min-width: 220px;
    a {
      padding: 0.5rem;
      opacity: 0;
      transform: translate3d(30%, 0, 0);
      transition: transform 0.3s, opacity 0.3s;
      text-transform: uppercase;
      font-size: 70%;
      letter-spacing: 1px;
      font-family: $montserrat-bold;
      display: flex;
    }
    &.show {
      opacity: 1;
      visibility: visible;
      pointer-events: all;
      will-change: auto !important;
      a {
        transform: translate3d(0, 0, 0);
        opacity: 1;
        &:hover {
          background-color: transparent;
          color: theme-color("primary");
        }
        $transition-delay: 0s;

        @for $i from 1 through 20 {
          &:nth-child(#{$i}) {
            transition-delay: $transition-delay;
          }

          $transition-delay: $transition-delay + 0.05s;
        }
      }
    }
  }
  @include media-breakpoint-down(md) {
    justify-content: space-between;
    .logo {
      img {
        max-width: 130px;
      }
    }
  }
}
#burger_menu {
  display: none;
  height: 40px;
  width: 40px;
  border-radius: 20px;
  align-items: center;
  justify-content: center;
}
.menu-icon {
  margin: 20px auto;
  width: 20px;
  height: 14px;
  cursor: pointer;
  position: relative;
  span {
    background-color: theme-color("primary");
    height: 3px;
    border-radius: 2px;
    position: absolute;
    width: 100%;
    left: 0;
    transition: all 0.3s ease;
    &:first-child {
      top: 0;
    }
    &:nth-child(2) {
      top: 6px;
    }
    &:last-child {
      top: 12px;
    }
  }
}
.sidr-open {
  .menu-icon {
    span {
      &:nth-child(2) {
        opacity: 0;
      }
      &:first-child,
      &:last-child {
        top: 6px;
        background-color: theme-color("white");
      }
      &:first-child {
        transform: rotate(45deg);
      }
      &:last-child {
        transform: rotate(-45deg);
      }
    }
  }
}
@include media-breakpoint-down(md) {
  .main__header {
    #burger_menu {
      display: flex;
    }
    .nav__site,
    .right__nav {
      display: none;
    }
  }
}
@media only screen and (max-width: 767px) {
  .main__header {
    #burger_menu {
      display: flex;
    }
    .nav__site,
    .right__nav {
      display: none;
    }
  }
}

.sidr-class-dropdown-menu {
  width: 100%;
  display: none;
  &.show {
    display: block;
  }
}
.sidr-class-dropdown {
  @extend .dropdown;
}

.sidr {
  background-color: theme-color("dark");
  a {
    color: white;
    font-family: $montserrat-bold;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 14px;
    &:hover {
      color: theme-color("primary");
    }
  }
  ul {
    list-style-type: none;
    padding-left: 0;
  }
  .sidr-inner {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .sidr-class-nav-link {
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;

    &::after {
      font-family: "Font Awesome 5 Free";
      content: "\f078";
      font-weight: 900;
      font-size: 10px;
      transition: transform 0.3s;
      transform-origin: center;
    }
    &.active {
      // color: theme-color('primary');
      &::after {
        transform: rotate(180deg);
      }
    }
  }
  .sidr-class-nav__site {
    li {
      &:not(:first-child) {
        border-top: 1px solid #474747;
      }
      &:not(:last-child) {
        border-bottom: 1px solid #242424;
      }
    }
  }
  .sidr-class-dropdown-item {
    padding: 1rem;
    display: block;
    font-size: 70%;
  }
  .sidr-class-dropdown-menu {
    background-color: darken(theme-color("dark"), 6);
    a {
      &:not(:last-child) {
        border-bottom: 1px solid #363636;
      }
    }
  }
}
.sidr-class-right__nav {
  padding: 1rem;
  .sidr-class-btn {
    @extend .btn;
    display: block;
    font-size: 12px;
    &.sidr-class-btn-primary {
      margin-bottom: 10px;
      @extend .btn-primary;
    }
    &.sidr-class-light {
      background-color: rgba(theme-color("primary"), 0.2);
    }
  }
  .sidr-class-dropdown {
    .sidr-class-dropdown-menu {
      display: block;
      background-color: transparent;
      a {
        padding: 10px 0;
        display: block;
      }
    }
  }
}

.cms-toolbar-offset {
  top: 46px !important;
}
