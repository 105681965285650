.app-blog {
  @include make-container();
  max-width: 1140px;

  @include media-breakpoint-down(md) {
    flex-wrap: wrap;
    padding-bottom: 20px;
  }
  .pagination {
    width: 100%;
    justify-content: center;
  }
  header {
    h2 {
      font-size: 28px;
      color: theme-color("blue");
    }
  }
  .pagination{
    margin-top: 20px;
    margin-bottom: 10px;
    position: relative;
    &::before{
      content: "";
      position: absolute;
      width: 100%;
      height: 1px;
      background-color: #eee;
      top: 50%;
    }
  }

  .blog-back {
    display: block;
    padding-top: 3rem;
    border-top: 1px solid #eee;
  }

  .blog-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
    @include media-breakpoint-down(xs) {
      grid-template-columns: 1fr;
    }
  }

  .blog-sidebar {
    padding-left: 40px;

    .blog-categories,
    .blog-tags,
    .blog-archive {
      margin-bottom: 30px;
      width: 100%;
    }

    .blog-archive {
      li {
        ul li {
          padding-left: 10px;
          border-left: 1px solid darken(white, 10);
        }
      }
    }

    h3 {
      font-size: 20px;
      color: theme-color("primary");
    }

    a {
      color: theme-color("text-color");
    }

    ul {
      list-style-type: none;
      padding-left: 0;

      li {
        padding: 5px 0px;

        span {
          display: none;
        }

        &:last-child {
          border-bottom: 0;
        }
      }

      ul {
        padding-left: 20px;
      }
    }
    .plugin-blog {
      margin-bottom: 1rem;
    }
  }

  .blog-back {
    @include make-col-ready();
    @include make-col(12);
  }
}
.post__item {
  &--img {
    position: relative;
    border-radius: 5px;
    margin-bottom: 20px;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 48px;
    a{
      display: block;
    }
    img {
      border-radius: 5px;
    }
    &::before {
      opacity: 0.24;
      background: #000000;
    }
  }
  &--content {
    h3 {
      font-size: 18px;
      a {
        color: theme-color("secondary");
      }
    }
  }
  .read-more {
    font-weight: bold;
  }
  .post__author {
    img {
      width: 50px;
      height: 50px;
    }
  }
}

.post-detail-list {
  display: flex;
  @include make-row();

  .post-item {
    @include make-col-ready();

    @include media-breakpoint-up(sm) {
      @include make-col(4);

      h3 {
        font-size: 20px;
      }

      .article-wrapper {
        background-color: $white;
        height: 100%;
        display: flex;
        flex-direction: column;

        .blog-lead {
          padding: 1rem;
          flex: 1;
          border: 1px solid #eee;
        }
      }
    }
  }
}

.post-item {
  transition: all 0.3s ease;

  h3 {
    transition: all 0.3s ease;

    a {
      color: inherit;
    }
  }

  &:hover {
    h3 {
      color: theme-color("primary");
    }
  }

  .article-wrapper {
    width: 100%;
  }
}

.post-detail {
  margin-bottom: 30px;
}

.blog_header {
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;

  text-align: center;
  background-color: #555;
  background-blend-mode: overlay;
  background-position: center center;

  @include media-breakpoint-up(md) {
    align-items: center;
    min-height: 600px;
  }

  @include media-breakpoint-down(md) {
    min-height: 200px;
    position: relative;
    align-items: center;

    > div {
      display: flex !important;
      min-height: 100% !important;
      align-items: center;
    }

    .breadcrumb_blog {
      background-color: rgba(theme-color("dark"), 0.5);
      margin-bottom: 10px;
    }
  }

  h1 {
    color: theme-color("white");
    text-align: center;
    color: #ffffff;
    text-shadow: 0 4px 11px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;

    @include media-breakpoint-down(md) {
      @include font-size(16px);
    }
  }

  .breadcrumb {
    justify-content: center;

    a {
      color: theme-color("primary");
    }
  }

  .post__author {
    img {
      border-radius: 50%;
      width: 60px;
      height: 60px;
      border: 2px solid #fff;
    }
  }
}

.blog-latest-entries {
  display: grid;
  margin-top: 2rem;
  position: relative;
  z-index: 1;
  @include media-breakpoint-up(md) {
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
  }

  article {
    margin-bottom: 20px;
    padding: 10px;
    .post__item--img {
      box-shadow: 0 9px 33px 0 rgb(0 0 0 / 12%);
      margin-bottom: 30px;
      img {
        min-width: 100%;
        height: auto;
      }
    }

    .post__author {
      padding-top: 1rem;
      img {
        border-radius: 50% !important;
        width: 50px;
        height: 50px;
        border: 2px solid theme-color("primary");
      }
      .author__name {
        color: theme-color("dark");
        font-family: $montserrat-bold;
      }
    }
    .post__item--content h3 {
      font-size: 20px;
    }

    .blog-visual {
      position: relative;
      margin-bottom: 1rem;
      a {
        display: block;
      }
    }

    .blog-lead {
      h3 {
        font-size: 20px;

        a {
          color: inherit;
        }
      }
    }

    .read-more {
      a {
        font-family: $montserrat-bold;
        &:hover {
          color: theme-color("blue");
        }
      }
    }
  }
}

.post__detail {
  h2 {
    font-size: 26px;
  }
  h3 {
    font-size: 22px;
  }
  .post-detail {
    margin-top: 10px;
    margin-bottom: 10px;
    list-style: none;
    padding-left: 0;
    display: flex;
    align-items: center;

    .post__author {
      img {
        border-radius: 50%;
        width: 50px;
        height: auto;
      }
    }

    li {
      margin-right: 1rem;
    }
  }
}

.blog {
  &__tags {
    list-style: none;
    margin: 0;
    overflow: hidden;
    padding: 0;

    li {
      float: left;
    }

    .tag {
      background: #eee;
      border-radius: 3px 0 0 3px;
      color: #999;
      display: inline-block;
      height: 26px;
      line-height: 26px;
      padding: 0 20px 0 23px;
      position: relative;
      margin: 0 5px 0px 0;
      text-decoration: none;
      transition: color 0.2s;

      a {
        color: inherit;
      }

      &::before,
      &::after {
        content: "";
        position: absolute;
      }

      &::before {
        background: #fff;
        border-radius: 10px;
        box-shadow: inset 0 1px rgba(0, 0, 0, 0.25);
        height: 6px;
        left: 10px;
        width: 6px;
        top: 10px;
      }

      &::after {
        background: #fff;
        border-bottom: 13px solid transparent;
        border-left: 10px solid #eee;
        border-top: 13px solid transparent;

        right: 0;
        top: 0;
      }

      &:hover {
        background-color: theme-color("primary");
        color: theme-color("gray");

        &::after {
          border-left-color: theme-color("primary");
        }
      }
    }
  }
}

.author {
  a {
    display: flex;
    align-items: center;
    margin-top: 10px;
    color: inherit;
  }
  &__name {
    font-weight: 900;
    color: theme-color("secondary");
  }
  .post__author {
    display: flex;
  }
}

.post__author--info {
  display: block;
  margin-left: 1rem;
  span {
    display: block;
  }
}

.post__categories,
.post__tags {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding-left: 0;
  margin-top: 1rem;
  li {
    display: inline-block;
    &:not(:last-child) {
      margin-right: 0.4rem;
    }
    a {
      display: inline-flex;
      font-size: 14px;
      font-weight: bold;
    }
  }
}
.post__tags {
  li {
    a {
      background-color: theme-color("primary");
      padding: 0.2rem 0.5rem;
      border-radius: 4px;
      color: #333;

      color: white;
    }
  }
}
.post__categories {
  li {
    margin-bottom: 10px;
    a {
      border: 2px solid #333;
      padding: 0.5rem 1rem;
      border-radius: 20px;
      color: #333;
      transition: all 0.3 ease;
      span {
        display: inline-block;
        margin-left: 0.5rem;
      }
      &:hover {
        color: white;
        background-color: #333;
      }
    }
  }
}
.share_box {
  .social-share-buttons {
    display: flex;
    margin-left: 0;
    a {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      height: 32px;
      width: 32px;
      border-radius: 16px;
      border: 1px solid #eee;
      transition: all 0.3 ease;
      &:not(:last-child) {
        margin-right: 0.5rem;
      }
      &:hover {
        background-color: theme-color("primary");
        i {
          color: white;
        }
      }
    }
  }
}

.premium__blog {
  padding: 1rem;
  text-align: center;
  border: 1px solid theme-color("primary");
  background: darken($white, 2);
  .title {
    padding-bottom: 1rem;
    border-bottom: 1px solid #eee;
  }
  h2 {
    &::after {
      left: 50% !important;
      margin-left: -20px;
    }
  }
  .why__subscribe {
    padding-top: 1rem;
    border-top: 1px solid #fff;
    display: flex;
    flex-wrap: wrap;
    > div {
      padding: 1rem;
      flex: 1;
      &.action {
        min-width: 100%;
      }
    }
    .icn {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 30px;
      height: 30px;
      background-color: theme-color("primary");
      color: $white;
      border-radius: 15px;
      margin-bottom: 30px;
    }
  }
}

.post__abstract {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 2rem;
  &.post__premium {
    position: relative;
    &::after {
      background-image: linear-gradient(
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0.9),
        #fff
      );
      height: 60%;
      bottom: 0rem;
      position: absolute;
      left: 0;
      content: " ";
      width: 100%;
    }
  }
}

.widget_blog__list {
  .widget_blog__item {
    @include make-col-ready();

    @include media-breakpoint-up(sm) {
      @include make-col(6);
    }

    @include media-breakpoint-up(lg) {
      @include make-col(4);
    }
    .block__wrapper {
      background-color: white;
      padding: 1rem;
      border: 1px solid #eee;
      margin-bottom: 10px;
      min-height: 100%;
      img {
        min-width: 100%;
      }
      .content {
        margin-top: 10px;
        h3 {
          font-size: 18px;
          a {
            color: inherit;
          }
        }
      }
    }
  }
}

.blog-categories {
  li {
    border-bottom: 1px dashed darken(white, 10);

    a {
      display: flex;
      justify-content: space-between;
    }

    span {
      display: block;
      font-size: 12px;
    }
  }
}

.article_date {
  color: theme-color("primary");
  font-family: $montserrat-bold;
  font-size: 20px;
}
.blog__sidebar {
  h2 {
    font-size: 20px;
  }
  .post-detail-list {
    margin-top: 30px;
    margin-bottom: 10px;
    margin-left: 0;
    margin-right: 0;
    article {
      position: relative;
      margin-bottom: 20px;
      .abstract,
      .read-more {
        display: none;
      }
      h3 {
        font-size: 18px;
      }
      .author {
        position: absolute;
        top: 10px;
        left: 10px;
        .post__author--info {
          display: none;
        }
      }
      .post__author img {
        border: 2px solid #fff;
      }
    }
  }
}
