$fa-font-path: '../fonts/fontawesome/';
$border-radius: 0;

$theme-colors: (
  'primary': #88bd30,
  'secondary': #8b669d,
  'gray': #eee,
  'dark-gray': #888,
  'text-color': rgba(#000, .57),
  'black-project': #2c2a34,
  'facebook': #3b5998,
  'twitter': #2aa3ef,
  'instagram': #b73678,
  'linkedin': #0073b1,
  'googleplus': #d34836,
  'youtube': #c4302b,
  'bg-light': #f5f5f5,
  'yellow': #F7CA18,
  'orange': #f78b18,
  'dark': #383838,
);
@font-face {
  font-family:"Hand Of Sean Pro";
  src:url("../fonts/HandOfSeanPro.woff2") format("woff2");
  font-style:normal;
  font-weight:400;
  font-display: swap;
}
$handofsean: 'Hand Of Sean Pro', sans-serif;

@font-face {
  font-family: 'Montserrat Bold';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/montserrat/Montserrat-Bold.woff2') format('woff2'),
       url('../fonts/montserrat/Montserrat-Bold.woff') format('woff'),
       url('../fonts/montserrat/Montserrat-Bold.otf') format('opentype');
}
$montserrat-bold: 'Montserrat Bold', sans-serif;

@font-face {
  font-family: 'Montserrat Medium';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/montserrat/Montserrat-Medium.woff2') format('woff2'),
       url('../fonts/montserrat/Montserrat-Medium.woff') format('woff'),
       url('../fonts/montserrat/Montserrat-Medium.otf') format('opentype');
}
$montserrat-medium: 'Montserrat Medium', sans-serif;
@font-face {
  font-family: 'Montserrat Semi Bold';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/montserrat/Montserrat-SemiBold.woff2') format('woff2');
}
$montserrat-semi: 'Montserrat Semi Bold', sans-serif;

@font-face {
  font-family: 'Montserrat Regular';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/montserrat/Montserrat-Regular.woff2') format('woff2'),
       url('../fonts/montserrat/Montserrat-Regular.woff') format('woff'),
       url('../fonts/montserrat/Montserrat-Regular.otf') format('opentype');
}
$montserrat-regular: 'Montserrat Regular', sans-serif;
