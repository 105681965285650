#id_index {
  .container-fluid {
    max-width: 1500px;
  }
}

.heading {
  padding: 2rem;
  text-align: center;
  background-image: url("../img/bg_network.png");
  background-repeat: no-repeat;
  background-position: top right;
  h1 {
    font-family: $montserrat-bold;
    color: theme-color("dark");
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
    @include media-breakpoint-down(md) {
      font-family: $handofsean;
      font-size: 32px;
      line-height: 1;
      color: theme-color("secondary");
    }
    @include media-breakpoint-only(md) {
      font-size: 42px;
    }
  }
}

.services__form {
  .send {
    background-color: theme-color("secondary");
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    color: white;
    i {
      display: inline-block;
      margin-left: 10px;
    }
  }
  @include media-breakpoint-up(lg) {
    position: absolute;
    z-index: 9999;
    transform: translateY(-50px);
    svg {
      position: absolute;
      bottom: -50px;
    }
    form {
      display: grid;
      grid-template-columns: 430px 200px;
      align-items: center;
      position: relative;
      > div {
        padding: 20px;
        min-height: 80px;
        display: flex;
        flex-direction: column;
        background-color: white;
        min-width: 300px;
        &:nth-child(2) {
          min-width: 450px;
        }
        label {
          display: block;
        }
        select {
          appearance: none;
          border: 0;
        }
      }
    }
    .send {
      border-top-right-radius: 20px;
    }
  }
  @include media-breakpoint-only(md) {
    margin-top: 20px;
    .cs-placeholder{
      padding-left: 20px;
    }
  }
  @include media-breakpoint-down(md) {
    background: #eee;
    padding: 20px 10px;
    text-align: center;
    svg {
      display: none;
    }
    .send {
      padding: 10px;
      width: 100%;
      margin-top: 10px;
    }
  }

  div.cs-skin-slide {
    max-width: 100%;
  }
  .icons .cs-skin-slide.cs-active::before {
    transform: scale3d(1.1, 5, 1);
  }
}

.notification {
  position: fixed;
  bottom: 30px;
  right: 30px;
  max-width: 600px;
  z-index: 9999;
  padding: 3rem;
  background-color: theme-color("black-project");
  color: $white;
  box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.09);

  .close-notif {
    position: absolute;
    background: $white;
    padding: 10px;
    border-radius: 20px;
    top: -10px;
    cursor: pointer;
    right: -10px;
    color: $black;
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.09);
  }

  ul {
    margin-bottom: 0;

    .success {
      color: theme-color("primary");
    }
  }
}

.detail__description {
  p {
    line-height: 2rem;
  }
}

.services__wrapper {
  min-height: 400px;
  padding-top: 40px;
  position: relative;
  @include make-row();

  &:before {
    content: "";
    position: absolute;
    top: 0;
    width: 100%;
    height: 200px;
    background-image: linear-gradient(-225deg, #87bc2f 15%, #7ace5c 100%);
    z-index: -1;
  }

  .owl-stage-outer {
    padding-bottom: 20px;
  }

  .owl-nav {
    position: absolute;
    right: 0;

    button {
      border: 1px solid #333 !important;
      height: 40px;
      width: 40px;
      border-radius: 40px;
      margin: 5px;
      transition: all 0.5s ease-in;
      background: transparent;

      &:hover {
        background: linear-gradient(-225deg, #87bc2f 15%, #7ace5c 100%);
        box-shadow: 0 52px 85px 0 rgba(0, 0, 0, 0.09) !important;
        border: 0 !important;
        color: $white !important;
      }
    }
  }

  .item {
    background: $white;
    border-radius: 6px;
    min-height: 400px;
    text-align: center;
    box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.09);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: all 0.3s linear;
    color: theme-color("text-color");
    position: relative;

    &::before {
      content: "";
      position: absolute;
      background-image: url("../img/pictovert.png");
      background-repeat: no-repeat;
      height: 75px;
      width: 75px;
      bottom: 10px;
      right: 10px;
      opacity: 0;
      transition: all 0.3s ease;
      transform: scale(0.5);
    }

    h3 {
      font-size: 18px;
      text-transform: uppercase;
      margin-top: 20px;
      transition: all 0.3s linear;
      padding-left: 10px;
      padding-right: 10px;
    }

    &__icon {
      height: 140px;
      width: 140px;
      border-radius: 70px;
      background: theme-color("gray");
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.3s linear;

      .lancer {
        @include retina-sprite($sprite_lancer-group);
      }

      .commercialiser {
        @include retina-sprite($sprite_commercialiser-group);
      }

      .innover {
        @include retina-sprite($sprite_innover-group);
      }

      .export {
        @include retina-sprite($sprite_export-group);
      }

      .learn {
        @include retina-sprite($sprite_learn-group);
      }

      .stockup {
        @include retina-sprite($sprite_tree-group);
      }
      .bio {
        background-image: url("../img/bio-hover.png");
        width: 130px;
        height: 130px;
        background-size: cover;
        mix-blend-mode: multiply;
      }
    }

    &:hover {
      &::before {
        opacity: 1;
        transform: scale(1);
      }

      h3 {
        color: theme-color("primary");
      }

      .item__icon {
        transform: translateY(-20px);
      }

      .item {
        &__icon {
          .lancer {
            @include retina-sprite($sprite_lancer_hover-group);
          }

          .commercialiser {
            @include retina-sprite($sprite_commercialiser_hover-group);
          }

          .innover {
            @include retina-sprite($sprite_innover_hover-group);
          }

          .export {
            @include retina-sprite($sprite_export_hover-group);
          }

          .learn {
            @include retina-sprite($sprite_learn_hover-group);
          }

          .stockup {
            @include retina-sprite($sprite_tree_hover-group);
          }
          .bio {
            background-image: url("../img/bio.png");
            background-size: cover;
            mix-blend-mode: normal;
          }
        }
      }
    }
  }
}

.services {

  background-image: url("../img/bg_1.svg");
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
  margin-bottom: 2rem;
  @include media-breakpoint-up(md) {
    padding-bottom: 8rem;
  }
  @include media-breakpoint-down(md) {
    padding-top: 2rem;
    padding-bottom: 5rem;
    margin-bottom: 3rem;

  }
  @include media-breakpoint-down(xs) {
  }
  .wrapper {
    @include media-breakpoint-up(md) {
      &::before {
        content: "";
        display: block;
        background-image: url("../img/picto_feuille.svg");
        background-repeat: no-repeat;
        background-size: contain;
        height: 400px;
        width: 400px;
        position: absolute;
        top: 10%;
        left: 50%;
        transform: translateX(-50%);
        opacity: 0.3;
      }
    }
  }

  h4,
  h5,
  h6 {
    text-transform: uppercase;
  }

  .container-fluid {
    max-width: 1500px;
  }
  .logos_banner {
    display: flex;
    align-items: center;
    justify-content: center;
    filter: grayscale(1);
    margin-bottom: 3rem;
    @include media-breakpoint-down(xs) {
      margin-bottom: 1rem;
    }
    .logos {
      display: grid;
      width: 100%;
      gap: 1rem;
      justify-content: center;
      align-items: center;
      @include media-breakpoint-up(md) {
        grid-template-columns: repeat(auto-fit, minmax(100px, 160px));
      }
      @include media-breakpoint-down(md) {
        padding: 0 1rem;
        grid-template-columns: repeat(6, 1fr);
      }
      .item {
        text-align: center;
      }
      a {
        display: block;
      }
      im {
        max-width: 100%;
        height: 100%;
      }
    }
  }
  .img__wrapper {
    img {
      border-top-right-radius: 60px;
      max-width: 100%;
      height: auto;
    }
  }
}

.keys {
  position: relative;
  @include media-breakpoint-up(md) {
    margin-top: 2rem;
    &::before {
      content: "";
      background-image: url("../img/fleur.svg");
      background-repeat: no-repeat;
      background-size: contain;
      position: absolute;
      right: 0;
      display: block;
      height: 400px;
      width: 240px;
      top: 0;
    }
  }

  p {
    font-style: italic;
    font-size: 18px;
    line-height: 1.3;
    font-family: $montserrat-medium;
    strong {
      font-family: $handofsean;
      font-weight: normal;
      font-size: 70px;
      line-height: 1.5;
      color: theme-color("secondary");
    }
  }
  @include media-breakpoint-down(md) {
    text-align: center;
    p {
      font-size: 16px;
      strong {
        font-size: 40px;
      }
    }
  }
}
.curator {
  margin-top: 30px;
}
.section__blog {
  position: relative;
  @include media-breakpoint-up(lg) {
    padding-top: 100px;
    padding-bottom: 100px;
  }
  @include media-breakpoint-down(md) {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  &::after,
  &::before {
    content: "";
    position: absolute;
    background-image: url(../img/bg3.svg);
    background-repeat: no-repeat;
    background-size: contain;
  }
  &::after {
    top: 0%;
    right: 0;
    height: 290px;
    width: 43px;
    transform: scale(-1) translateY(-50%);
  }
  &::before {
    left: 0;
    @include media-breakpoint-up(md) {
      bottom: -10%;
      height: 490px;
      width: 143px;
    }
    @include media-breakpoint-down(md) {
      height: 270px;
      width: 23px;
      bottom: 0;
    }
  }
}
.post__item {
  @include media-breakpoint-down(sm) {
    background-color: white;
    border: 1px solid #eee;
    margin-bottom: 20px;
    box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
    border-radius: 5px;
    .post__item--img {
      margin-bottom: 20px;
    }
    .post__item--content {
      padding: 1rem;
      h3 {
        font-size: 18px;
      }
    }
    .author__name {
      font-size: 14px;
      font-family: $montserrat-medium;
    }
  }
}
.cluster__bio {
  margin-top: 5rem;

  .img__wrapper {
    position: relative;

    img {
      width: 100%;
      height: auto;
      border-radius: 5px;
    }

    &::before {
      content: "";
      position: absolute;
      height: 100%;
      width: 100%;
      background-color: darken($white, 5);
      top: -20px;
      left: -20px;
      z-index: -1;
      border-radius: 5px;
    }
  }

  .text__wrapper {
    padding: 20px 40px;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      height: 160px;
      width: 160px;
      background-image: url(../img/picto_feuille.png);
      background-repeat: no-repeat;
    }

    ul {
      list-style-type: none;

      li {
        position: relative;
        line-height: 2.5;

        &::before {
          font-family: "Font Awesome 5 Free";
          content: "\f00c";
          font-weight: 900;
          font-size: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: theme-color("primary");
          height: 16px;
          width: 16px;
          border-radius: 16px;
          position: absolute;
          background-color: rgba(theme-color("primary"), 0.3);
          top: 50%;
          margin-top: -8px;
          left: -30px;
        }
      }
    }
  }
}

.newsletter {
  background: #ffffff;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 20px 30px;
  @include media-breakpoint-up(md) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
  }
  @include media-breakpoint-only(md) {
    button{
      width: 100%;
      margin-top: 10px;
    }
  }
  @include media-breakpoint-down(md) {
    text-align: center;
    input {
      min-width: 100%;
      margin-right: 0;
    }
  }
  h2 {
    font-size: 20px;
    padding-bottom:0 !important;
    margin-bottom: 10px;
    &::before, &::after{
      display:none;
    }
  }
  p {
    margin-bottom: 0;
  }
  .newsletter__form {
    display: flex;
    justify-content: flex-end;
    .form-inline {
      width: 100%;
    }
  }
  input {
    height: 46px;
    margin-right: 10px;
    border-radius: 5px;
    background: #f5f5f5;
    border: 1px solid #eee;
    flex: 1;
    color: #333;
  }

  .social__items {
    li {
      margin-left: 20px;
      margin-right: 20px;

      &:first-child {
        margin-left: 0;
      }
    }

    a {
      font-size: 2.2rem;
    }
  }

  .facebook {
    a {
      color: theme-color("facebook");
    }
  }

  .twitter {
    a {
      color: theme-color("twitter");
    }
  }

  .instagram {
    a {
      color: theme-color("instagram");
    }
  }

  @include media-breakpoint-down(sm) {
    form {
      button,
      input[type="submit"] {
        width: 100%;
        margin-top: 10px;
      }
    }
  }
}

.section {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.partners {
  .owl-stage-outer {
    display: flex;
    justify-content: center;
  }

  .item {
    text-align: center !important;
  }

  img {
    width: auto !important;
    display: inline-block !important;
  }

  .owl-stage {
    display: flex;
    align-items: center;
  }
  @include media-breakpoint-down(sm) {
    img {
      max-width: 90%;
    }
  }
}

.events {
  position: relative;
  @include media-breakpoint-up(md) {
    padding-bottom: 100px;
    padding-top: 5rem;
    &::before {
      content: "";
      background-image: url("../img/fleur2.svg");
      background-repeat: no-repeat;
      background-size: contain;
      position: absolute;
      left: 0;
      top: -200px;
      display: block;
      height: 550px;
      width: 360px;
    }
  }
  @include media-breakpoint-down(sm) {
    padding-bottom: 20px;
  }

  .container-fluid {
    max-width: 1500px;
  }
}
.events__title {
  display: flex;
  @include media-breakpoint-up(md) {
    justify-content: space-between;
    align-items: flex-end;
    h2 {
      margin-bottom: 0;
    }
  }
  @include media-breakpoint-down(sm) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    div + div {
      a {
        background-color: transparent;
        color: theme-color("secondary");
        border: 0;
        padding: 0;
      }
    }
  }
}
.events__slider {
  max-width: 1500px;
  margin-left: auto;
  position: relative;
  margin-right: auto;

  @include media-breakpoint-up(lg) {
    margin-top: 80px;
    &::before,
    &::after {
      content: "";
      height: 100%;
      width: 180px;
      z-index: 1;
      position: absolute;
      top: 0;
      bottom: 0;
    }
    &::before {
      left: 0;
      background-image: linear-gradient(
        90deg,
        #ffffff 0%,
        rgba(255, 255, 255, 0) 100%
      );
    }
    &::after {
      right: 0;
      background-image: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 0%,
        #ffffff 100%
      );
    }
  }
  @include media-breakpoint-only(md) {
    @include make-container();
  }

  .owl-dots {
    display: flex;
    justify-content: center;
    gap: 10px;
    button {
      span {
        display: inline-block;
        width: 16px;
        height: 16px;
        border-radius: 8px;
        background-color: theme-color("dark");
      }
      &.active {
        span {
          background-color: theme-color("primary");
        }
      }
    }
  }
  .owl-nav {
    top: 50%;
    margin-top: -30px;
    z-index: 99;
    height: 60px;
    left: 0;
    right: 0;
    position: absolute;
    button {
      background-color: #ffffff !important;
      box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.14);
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      width: 52px;
      height: 60px;
      position: absolute;
      transition: all 0.3s;
      &.owl-prev {
        left: 50px;
      }
      &.owl-next {
        right: 50px;
      }
      &:hover {
        background-color: theme-color("secondary") !important;
        color: white;
        transform: scale(1.2);
      }
    }
  }

  .owl-stage-outer {
    padding: 30px 0;
    background-color: white;
  }
  @include media-breakpoint-down(sm) {
    padding: 1rem;
    .owl-stage-outer {
      padding-bottom: 10px;
    }
  }
}

.event {
  position: relative;
  padding: 10px;
  background-color: white;
  .event__link {
    display: block;
    @include media-breakpoint-up(md) {
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    }
    @include media-breakpoint-down(md) {
      border: 1px solid #eee;
      border-radius: 5px;
    }
  }
  @include media-breakpoint-only(md) {
    padding:0;
    .event__content{
      position: absolute;
      bottom: 25px;
      left: 25px;
      right: 25px;
      padding:10px;
      background-color:white;
      border-radius:5px;
    }
  }
  h3 {
    font-family: $montserrat-bold;
    font-size: 20px;
    transition: color 0.3s;
    color: theme-color("secondary");
    margin-bottom: 0;
  }
  img {
    border-radius: 5px;
    max-width: 100%;
    height: auto;
    @include media-breakpoint-down(sm) {
      height: 300px;
      object-fit: cover;
    }
  }
  .content {
    text-align: center;
    @include media-breakpoint-up(lg) {
      position: absolute;
      bottom: 30px;
      right: 30px;
      left: 30px;
      background-color: rgba(white, 0.9);
      border-radius: 5px;
      padding: 20px;
    }
    @include media-breakpoint-down(sm) {
      padding: 2rem 1rem;
      h3 {
        font-size: 18px;
      }
    }
  }
  .article-event-date {
    background-color: theme-color("primary");
    position: absolute;
    top: -10px;
    left: 30px;
    padding: 10px 20px;
    color: white;
    border-radius: 5px;
    font-family: $montserrat-bold;
    text-align: center;
    span {
      display: block;
      &.date-range-on {
        display: none;
      }
    }
  }
}

.events__wrapper {
  position: relative;
  z-index: 1;
  &.box__content{
    .events{
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 20px;
      padding-bottom:0;
    }
  }
}

.blur-effect {
  pointer-events: none;
  filter: grayscale(1) blur(2px);
  opacity: 0.3;
}

.cms__events {
  .event {
    flex-direction: column;

    h3 {
      color: theme-color("secondary");
    }

    &__content {
      padding: 10px;
      flex: 1;
    }

    &__img {
      flex: 1;

      img {
        height: 100%;
        width: auto;
      }
    }

    .article-event-date {
      color: theme-color("text-color");
    }
  }
}

//CMs pages
.page__header {
  background-color: theme-color("black-project");
  position: relative;
  padding: 20px;
  margin: 20px;
  border-radius: 5px;
  overflow: hidden;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  @include media-breakpoint-up(lg) {
    min-height: 245px;
  }
  @include media-breakpoint-down(sm) {
    min-height: 100px;
    margin-top: 10px;
  }
  .breadcrumb {
    li + li {
      &::before {
        color: $white !important;
      }
    }
  }
  @include media-breakpoint-down(sm) {
    h1 {
      font-size: 26px !important;
    }
  }

  .bg {
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #222;
      opacity: 0.7;
    }
  }

  .page__title {
    position: relative;
  }

  h1 {
    color: $white;
    font-size: 30px;
    font-family: $montserrat-bold;
  }

  .breadcrumb {
    color: darken($white, 20);
  }
}

//teaser
.widget {
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease;
  box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.08);
  color: theme-color("text-color");

  .teaser-image {
    padding: 5px;

    img {
      width: 100%;
      height: auto;
    }
  }

  .teaser-content {
    padding: 20px;
  }

  &:hover {
    box-shadow: 0 9px 33px 0 rgba(0, 0, 0, 0.12);
  }
}

.social__wall {
  @include media-breakpoint-down(sm) {
    display: none;
  }

  .filter-options {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;

    i {
      font-size: 22px;
    }

    .btn {
      background-color: transparent;
    }
  }
}

#social_wall_wrapper {
  .owl-stage,
  .owl-item {
    display: flex;
  }

  .item {
    img {
      width: 100%;
      height: auto;
    }

    overflow: hidden;
    font-size: 14px;
    font-weight: bold;
    background: $white;

    .content-wrapper {
      border: 1px solid rgba(0, 0, 0, 0.1);
      height: calc(100% - 20px);
      box-shadow: 0 5px 35px 0 rgba(0, 0, 0, 0.1);
      border-bottom: 3px;
      border-bottom-style: solid;
      padding: 2rem;
    }

    &.twitter {
      .content-wrapper {
        border-color: theme-color("twitter");
      }

      a {
        color: theme-color("twitter");
      }
    }

    &.facebook {
      .content-wrapper {
        border-color: theme-color("facebook");
      }

      a {
        color: theme-color("facebook");
      }
    }
  }
}

.facebook {
  i {
    color: theme-color("facebook");
  }
}

.twitter {
  i {
    color: theme-color("twitter");
  }
}

.linkedin {
  i {
    color: theme-color("linkedin");
  }
}
.youtube {
  i {
    color: theme-color("youtube");
  }
}

.filer_folder_list {
  position: relative;
}

#grid__gallery,
#articles__news {
  .column {
    float: left;

    li {
      margin: 0.5em 0.25em;
    }
  }

  .size-1of2 {
    width: 50%;
  }

  .size-1of3 {
    width: 33.33333%;
  }

  .size-1of4 {
    width: 25%;
  }
}

// /kapt news
.archive-content {
  .articles {
    display: flex;
    flex-wrap: wrap;

    li {
      &.biondays {
        min-width: 100%;
        img {
          max-width: 100%;
        }
      }

      margin-bottom: 10px;

      .article__wrapper {
        border: 1px solid #eee;
        display: flex;
        flex-direction: column;
        padding: 10px;
        color: inherit;
        height: calc(100% - 10px);

        &:hover {
          color: theme-color("primary");
        }

        h2 {
          font-size: 18px;
          margin-top: 10px;
        }
        .content {
          p {
            text-overflow: ellipsis;
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
          }
        }
      }
    }
  }
}

#id_news,
#id_archive {
  .page__header {
    background-color: theme-color("secondary");
  }
}

#id_adherent {
  .header__pro {
    box-shadow: 0px 1px 15px 1px rgba(69, 65, 78, 0.1);
  }

}

#id_adherent,
#id_registration,
#id_inscription_adherent,
.espace_membres {
  background: theme-color("bg-light");
}

.facture {
  @include retina-sprite($sprite_approve-invoice-group);
  display: inline-block;
}
.team {
  @include retina-sprite($sprite_team-group);
  display: inline-block;
}
//member area
.box_vote{
  margin-bottom: 15px;
}
.boxes__wrapper {



  @include media-breakpoint-up(md) {
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(3, 1fr);
  }
  @include media-breakpoint-up(lg) {
    grid-template-columns: repeat(4, 1fr);
  }
  &.line__one{
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
  .box {
    display: block;

    @include media-breakpoint-down(md) {
      margin-bottom: 20px;
    }

    @include media-breakpoint-up(lg) {
    }
    &.full {
      @include media-breakpoint-up(md) {
        grid-column: 1/-1;
      }
    }
    &.box3 {
      grid-column: 2/-1;
      @include media-breakpoint-only(md) {
        grid-column: 1/-1;
      }
    }
    &.box2 {
      grid-column: 1/-3;
      @include media-breakpoint-only(md) {
        grid-column: 1/-1;
      }
    }
    &.no-icon {
      .box__content {
        text-align: left;
        .h2 {
          margin-top: 0;
          padding-top: 0;
          border-top: 0;
        }
      }
    }
    &.green,
    &.purple {
      .h2 {
        margin-top: 0;
      }
    }
    &.purple {
      color: white;

      h2 {
        color: white !important;
      }
    }
    color: inherit;

    &__content {
      box-shadow: 0px 1px 15px 1px rgba(69, 65, 78, 0.08);
      background-color: $white;
      height: 100%;
      padding: 1rem;
      text-align: center;

      .icon__wrapper {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: theme-color("bg-light");

        i {
          color: theme-color("secondary");
          font-size: 1.6rem;
        }
      }

      .h2 {
        margin-top: 1rem;
        padding-top: 1rem;
        border-top: 1px dashed rgba(69, 65, 78, 0.08);
        font-size: 1.2rem;
        font-family: $montserrat-medium;
        color: theme-color("primary");
      }
    }
    &.purple {
      .box__content {
        background-color: theme-color("secondary");
        .h2 {
          color: $white;
          border-top: 0;
        }
        .top {
          margin-top: -15px;
          padding-top: 10px;
          margin-left: -15px;
          margin-right: -15px;
          padding-bottom: 10px;
          margin-bottom: 30px;
          background-color: darken(theme-color("secondary"), 6);
        }
        .list {
          text-align: left;
          margin-top: 10px;
          li {
            padding-top: 5px;
            padding-bottom: 5px;
            border-bottom: 1px dashed darken(theme-color("secondary"), 6);
            &:last-child {
              border-bottom: 0;
            }
          }
          a {
            color: white;
          }
        }
      }
    }
    &.green {
      .box__content {
        background-color: theme-color("primary");
        color: white;
        .h2 {
          color: $white;
          border-top: 0;
        }
        .top {
          margin-top: -15px;
          padding-top: 10px;
          margin-left: -15px;
          margin-right: -15px;
          padding-bottom: 10px;
          margin-bottom: 30px;
          background-color: darken(theme-color("primary"), 6);
        }
        .list {
          text-align: left;
          margin-top: 10px;
          li {
            padding-top: 5px;
            padding-bottom: 5px;
            border-bottom: 1px dashed darken(theme-color("secondary"), 6);
            &:last-child {
              border-bottom: 0;
            }
          }
          a {
            color: white;
          }
        }
      }
    }
    &__link {
      display: flex;
      background-color: #ececec;
      margin-bottom: 10px;
      align-items: center;
      height: 50px;
      color: theme-color("dark-gray");
      .text {
        display: flex;
        text-align: center;
        justify-content: center;
        padding: 1rem;
        font-size: 14px;
        min-width: 80%;
      }
      i {
        display: flex;
        background-color: darken(#ececec, 10);
        justify-content: center;
        align-items: center;
        padding: 1rem;
        color: theme-color("secondary");
      }
    }
    .widget_blog__list {
      .widget_blog__item {
        @include media-breakpoint-up(lg) {
          @include make-col(3);
        }
      }
    }
  }
}

.biondays-img {
  margin-bottom: 10px;
  img {
    max-width: 100%;
  }
}

.connexion_blog {
  a {
    display: inline-block;
    margin-left: 10px;
  }
}

.membres__space {
  main {
    min-height: 100vh;
  }
}

.main__member {
  padding-top: 70px;
  margin-top: -3rem;
  border-top: 1px solid #ddd;
}

.wizard-tracking-progress {
  .steps {
    display: flex;
    justify-content: center;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      height: 4px;
      width: 100%;
      background: #eee;
      left: 0;
      right: 0;
      top: 50%;
      z-index: -1;
    }

    li {
      margin: 1rem;
      background: theme-color("bg-light");
      padding: 1rem;

      .step-number {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        height: 40px;
        width: 40px;
        font-weight: bold;
        border: 2px solid #ccc;
        background: $white;
      }

      &.ok {
        .step-number {
          border: 2px solid theme-color("primary");
        }
      }
    }
  }
}

.formset__item {
  position: relative;
  margin-bottom: 1rem;

  background: #f5f5f5;

  .row {
    margin: 0;
    padding-top: 1rem;
  }

  .remove__field {
    position: absolute;
    height: 40px;
    width: 40px;
    right: 10px;
    bottom: 10px;
    z-index: 1;
    background-color: $red;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $white;
  }
}

.amount__wrapper {
  display: flex;
  align-items: center;
  font-weight: bold;

  &.ht {
    border-top: 1px dashed #eee;
    padding-top: 1rem;
  }

  div {
    margin-left: 10px;
    color: theme-color("primary");
    font-size: 1.2rem;
  }
}

#id_paiement-moyen_paiement,
#id_moyen_paiement {
  list-style-type: none;
}

.recap {
  margin-bottom: 3rem;
  border: 1px solid #ccc;

  h3 {
    color: theme-color("primary");

    &:not(.first) {
      margin-top: 2rem;
    }
  }

  &__item {
    border-bottom: 1px solid #eee;
    padding: 0.3rem;

    &:nth-child(odd) {
      background: #f5f5f5;
    }
  }
}

.recap__item {
  &__label {
    min-width: 40%;
    font-weight: bold;
    color: $black;
  }
}

.errorlist {
  @extend .list-unstyled;
}
.errornote,
.errorlist {
  color: $red;
  font-size: 14px;
}
.errornote {
  padding: 10px;
}

.list__zibra {
  display: flex;
  flex-direction: column;
  width: 100%;

  li {
    display: flex;
    width: 100%;
    padding: 10px;

    &.first {
      border-bottom: 2px solid #ccc;
      background-color: $white;
    }

    &:not(.first) {
      border-bottom: 1px solid #ddd;
    }

    * {
      flex: 1;
    }
  }
}

.text__hlper {
  padding: 20px;
  background: $white;
  border: 1px dashed #ddd;
  margin-bottom: 20px;
  box-shadow: 1px 0px 30px 0px rgba($black, 0.07);

  &:empty {
    display: none;
  }
}

// .premium {
//   margin-top: 20px;
//   padding: 2rem;
//   border: 1px solid #eee;
//   background: #f5f5f5;
//   img {
//     width: 180px;
//     margin-right: 20px;
//   }
//   &-btn {
//     background-color: theme-color("yellow");
//     display: inline-block;
//     color: darken(theme-color("yellow"), 30);
//     font-size: 20px;
//     text-transform: uppercase;
//     i {
//       margin-right: 10px;
//       margin-left: 10px;
//       color: white;
//     }
//     span {
//       background: darken(theme-color("yellow"), 5);
//       padding: 10px;
//       width: 50px;
//       text-align: center;
//       display: inline-flex;
//       justify-content: center;
//       align-items: center;
//       height: 50px;
//     }
//     label {
//       font-size: 18px;
//       margin-bottom: 0;
//       margin-right: 10px;
//     }
//   }
// }

.alert-error {
  background: theme-color("danger");
  color: white;
}

.member-notification {
  position: fixed;
  bottom: 30px;
  right: 30px;
  max-width: 600px;
  z-index: 9999;
}

.customer_type {
  justify-content: space-between;
  @include media-breakpoint-down(sm) {
    flex-direction: column;
    a {
      display: block;
    }
  }
}
.categories_link {
  display: grid;
  @include media-breakpoint-up(lg) {
    grid-template-columns: repeat(3, 1fr);
  }
  @include media-breakpoint-only(md) {
    grid-template-columns: repeat(2, 1fr);
  }
  @include media-breakpoint-down(sm) {
    grid-template-columns: repeat(2, 1fr);
  }
  flex-wrap: wrap;
  a {
    display: inline-flex;
    align-items: center;
    @include media-breakpoint-up(sm) {
      height: 60px;
    }
    margin-bottom: 0.3rem;
    margin-right: 0.3rem;
    font-size: 14px;
    padding: 0.6rem;
    padding-left: 1rem;
    text-align: center;
    @include media-breakpoint-down(sm) {
      flex-direction: column;
    }

    i.icon {
      transform: scale(0.5);
      margin-right: 1rem;
      display: inline-block;
      @include media-breakpoint-down(sm) {
        display: block;
        margin-bottom: 1rem;
        transform: scale(0.3);
      }
      &.se-lancer {
        @include retina-sprite($sprite_lancer-group);
      }
      &.se-financer {
        @include retina-sprite($sprite_se-financer-group);
      }

      &.commercialiser {
        @include retina-sprite($sprite_commercialiser-group);
      }

      &.innover {
        @include retina-sprite($sprite_innover-group);
      }

      &.international {
        @include retina-sprite($sprite_export-group);
      }

      &.se-former {
        @include retina-sprite($sprite_learn-group);
      }

      &.s-approvisionner {
        @include retina-sprite($sprite_tree-group);
      }
      &.animer-sa-marque {
        @include retina-sprite($sprite_animer-sa-marque-group);
      }
      &.vie-association {
        @include retina-sprite($sprite_vie-association-group);
      }
      &.bio {
        background-image: url("../img/bio-hover.png");
        width: 130px;
        height: 130px;
        background-size: cover;
        mix-blend-mode: multiply;
      }
    }
    &:hover,
    &.active {
      color: theme-color("secondary") !important;
      i.icon {
        &.se-lancer {
          @include retina-sprite($sprite_lancer_hover-group);
        }
        &.animer-sa-marque {
          @include retina-sprite($sprite_animer-sa-marque_hover-group);
        }
        &.vie-association {
          @include retina-sprite($sprite_vie-association_hover-group);
        }
        &.se-financer {
          @include retina-sprite($sprite_se-financer_hover-group);
        }

        &.commercialiser {
          @include retina-sprite($sprite_commercialiser_hover-group);
        }

        &.innover {
          @include retina-sprite($sprite_innover_hover-group);
        }

        &.international {
          @include retina-sprite($sprite_export_hover-group);
        }

        &.se-former {
          @include retina-sprite($sprite_learn_hover-group);
        }

        &.s-approvisionner {
          @include retina-sprite($sprite_tree_hover-group);
        }
      }
    }
  }
}

.ag_footer {
  background-color: darken(theme-color("light"), 10);
  span {
    font-size: 22px;
  }
}

.members-ca-about-title {
  margin-bottom: 1em;
}
.fieldset{
  border: 1px solid #cdcbcb;
  padding: 1rem;
  margin-bottom: 20px;
  legend{
    color: theme-color('primary');
    padding: 1rem;
    margin-bottom: 0;
    display: inline-block;
    width: auto;
  }
}
.list__prod{
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
  margin-bottom: 20px;
  >div{
    a{
      border-radius: 10px;
      display: block;
      padding: 1rem;
      background-color: white;
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
      text-align: center;
      font-weight: bold;
      font-size: 18px;
      &:hover{
        background-color: theme-color('primary');
        color: white;
      }
    }
  }
}
.h-auto{
  .form-control{
    height: auto ;
  }
}
#id_adherent {
  .events{
    &::before{
      display:none;
    }
    .event .article-event-date{
      right: 30px;
      top: 30px;
      span{
        display: inline-block;
        &:not(:last-child){
          margin-right: 0.2rem;
        }
      }
    }
  }
  .grid.d-flex {
    flex-wrap: wrap;
    > div {
      @include make-col-ready;
      @include make-col(12);
      @include media-breakpoint-up(sm) {
        @include make-col(6);
      }
    }
  }
}
#id_membres_ca {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 10px;
  padding: 1rem;
  li {
    text-align: center;
    padding-top: 1rem;
    border: 1px solid #eee;
    background-color: #f5f6f6;
    display: flex;
    flex-direction: column;
    align-items: center;

    video {
      margin-bottom: 1rem;
    }
    .name {
      font-size: 18px;
      padding: 1rem;
      text-transform: uppercase;
    }
    .user_img {
      width: 120px;
      height: 120px;
      border-radius: 50%;
      background-color: white;
      padding: 0.5rem;
      border: 1px solid #eee;
    }
    img {
      border-radius: 50%;
      display: inline-block;
      margin-bottom: 10px;
      width: 150px;
      height: auto;
    }
    .btn {
      margin-bottom: 20px;
    }
    .footer_li {
      background-color: theme-color("dark");
      padding: 10px;
      width: 100%;
      margin-top: auto;
      label {
        color: white;
      }
    }
  }
}

.form-checkbxes {
  ul.form-control {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    li {
      label {
        position: relative;
        padding-left: 20px;
        input {
          position: absolute;
          left: 0;
          top: 4px;
          width: auto;
        }
      }
    }
  }
}
.polling_intro {
  font-size: 18px;
  ul {
    padding-left: 40px;
    list-style: disc;
  }
}

.cms__events__grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}
