.row-background {
  padding-bottom: 30px;
  padding-top: 30px;
  h2,
  h3,
  h4 {
    &:first-child {
      margin-top: 0;
    }
  }
  a {
    &:hover {
      text-decoration: underline;
    }
  }
}
.multicolumn-wrapper{
  padding-bottom: 10px;
  padding-top: 10px;
}
.column-background {
  margin-bottom: 15px;
  overflow: hidden;
  padding: 20px;
  p {
    &:last-child {
      margin-bottom: 0;
    }
  }
  a {
    &:hover {
      text-decoration: underline;
    }
  }
  &.black-background {
    color: $white;
    color: $white;
    h2,
    h3 {
      color: theme-color('primary');
    }
    p,
    h4,
    h5,
    ul {
      color: $white;
    }
  }
}

.white-background {
  background-color: $white;
}

.green-background {
  background-color: theme-color('primary');
  a {
    &:not(.btn) {
      color: $white;
    }
  }
}

.black-background {
  background-color: lighten($black, 6);
}

.purple-background {
  background-color: theme-color('secondary');
  h2{
    color: $white;
  }
  a {
    &:not(.btn) {
      color: $white;
    }
  }
}

.gray-background {
  background-color: theme-color('gray');
}

.shadow-background {
  box-shadow: 0 9px 33px 0 rgba(0, 0, 0, .12);
}

.column-spacer {
  width: 100%;
}

.column-spacer-sm {
  height: 2em;
}

.column-spacer-md {
  height: 4em;
}

.column-spacer-lg {
  height: 8em;
}

.multicolumn-wrapper {
  @extend .container;
}

.multicolumn-background-image {
  background-blend-mode: overlay;
  background-size: cover;
  background-repeat: no-repeat;
}



.col-xs-25 {
  @include media-breakpoint-down(xs) {
    @include make-col-ready();
    @include make-col(3, 15);
  }
}

.col-sm-25 {
  @include media-breakpoint-only(sm) {
    @include make-col-ready();
    @include make-col(3, 15);
  }
}

.col-md-25 {
  @include media-breakpoint-only(md) {
    @include make-col-ready();
    @include make-col(3, 15);
  }
}

.col-lg-25 {
  @include media-breakpoint-up(lg) {
    @include make-col-ready();
    @include make-col(3, 15);
  }
}
