div.cs-select {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  text-align: left;
  background: #fff;
  z-index: 100;
  width: 100%;
  max-width: 500px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 1rem;
  @include media-breakpoint-up(sm) {
    padding-left: 0;
  }
  &:focus {
    outline: none;
  }
  select {
    display: none;
  }
  span {
    display: block;
    position: relative;
    cursor: pointer;
    padding: 0.5rem;
    @include media-breakpoint-up(sm) {
      padding-left: 0;
    }
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    @include media-breakpoint-down(sm) {
      font-size: 15px;
    }
  }
  /* Placeholder and selected option */
  > span {
    padding-right: 1rem;
  }
}





.cs-select > span::after,
.cs-select .cs-selected span::after {
  speak: none;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.cs-select > span::after {
  content: "\25BE";
  right: 1em;
}

.cs-select .cs-selected span::after {
  content: "\2713";
  margin-left: 1em;
}

.cs-select.cs-active > span::after {
  -webkit-transform: translateY(-50%) rotate(180deg);
  transform: translateY(-50%) rotate(180deg);
}

div.cs-active {
  z-index: 200;
}

/* Options */
.cs-select .cs-options {
  position: absolute;
  overflow: hidden;
  width: 100%;
  background: #fff;
  visibility: hidden;
}

.cs-select.cs-active .cs-options {
  visibility: visible;
}

.cs-select ul {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
}

.cs-select ul li.cs-focus span {
  background-color: #ddd;
}

/* Optgroup and optgroup label */
.cs-select li.cs-optgroup ul {
  padding-left: 1em;
}

.cs-select li.cs-optgroup > span {
  cursor: default;
}

div.cs-skin-slide {
  font-size: 1.1rem;
  font-weight: 700;
}

div.cs-skin-slide::before {
  content: "";
  background: white;
  position: absolute;
  width: 100%;
  height: 110%;
  top: 0;
  left: 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
}

.cs-skin-slide.cs-active::before {
  -webkit-transform: scale3d(1.1, 3, 1);
  transform: scale3d(1.1, 3, 1);
  background-color: #fff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.cs-skin-slide > span {
  line-height: 32px;
  -webkit-transition: text-indent 0.3s, opacity 0.3s;
  transition: text-indent 0.3s, opacity 0.3s;
  @include media-breakpoint-up(lg) {
    transform: translateY(-12px);
  }
}

.cs-skin-slide.cs-active > span {
  text-indent: -290px;
  opacity: 0;
}

.cs-skin-slide > span::after,
.cs-skin-slide.cs-active > span::after {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f107";
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.cs-skin-slide.cs-active > span::after {
  -webkit-transform: translate3d(0, -50%, 0);
  transform: translate3d(0, -50%, 0);
}

.cs-skin-slide .cs-options {
  background: transparent;
  width: 90%;
  padding: 1.2rem 0;
  top: 50%;
  left: 50%;
  -webkit-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0);
}

.cs-skin-slide .cs-options li {
  opacity: 0;
  -webkit-transform: translate3d(30%, 0, 0);
  transform: translate3d(30%, 0, 0);
  -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
  transition: transform 0.3s, opacity 0.3s;
}

.cs-skin-slide.cs-active .cs-options li {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  opacity: 1;
}

.cs-skin-slide.cs-active .cs-options li:first-child {
  -webkit-transition-delay: 0.05s;
  transition-delay: 0.05s;
}

.cs-skin-slide.cs-active .cs-options li:nth-child(2) {
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
}

.cs-skin-slide.cs-active .cs-options li:nth-child(3) {
  -webkit-transition-delay: 0.15s;
  transition-delay: 0.15s;
}

.cs-skin-slide.cs-active .cs-options li:nth-child(4) {
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
}

.cs-skin-slide.cs-active .cs-options li:nth-child(5) {
  -webkit-transition-delay: 0.25s;
  transition-delay: 0.25s;
}
.cs-skin-slide.cs-active .cs-options li:nth-child(6) {
  -webkit-transition-delay: 0.3s;
  transition-delay: 0.3s;
}
.cs-skin-slide.cs-active .cs-options li:nth-child(7) {
  -webkit-transition-delay: 0.35s;
  transition-delay: 0.35s;
}
.cs-skin-slide.cs-active .cs-options li:nth-child(8) {
  -webkit-transition-delay: 0.4s;
  transition-delay: 0.4s;
}

.cs-skin-slide .cs-options li span {
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 1px;
  font-size: 65%;
  @include media-breakpoint-down(md) {
    text-overflow: unset;
    overflow: unset;
    white-space: initial;
  }
}

.cs-skin-slide .cs-options li span:hover,
.cs-skin-slide .cs-options li.cs-focus span,
.cs-skin-slide .cs-options li.cs-selected span {
  color: theme-color("primary");
  background: transparent;
}

.cs-skin-slide .cs-selected span::after {
  content: "";
}

.cs-placeholder {
  color: theme-color("primary");
  font-family: $montserrat-medium;
  padding-left: 0;
}

.icons {
  .cs-skin-slide.cs-active::before {
    -webkit-transform: scale3d(1.1, 4.5, 1);
    transform: scale3d(1.1, 4.5, 1);
  }
  .cs-options li {
    span {
      display: grid;
      grid-template-columns: 40px 1fr;
      align-items: center;
      &::before {
        content: "";
        width: 30px;
        height: 30px;
        display: inline-block;
        margin-right: 10px;
        background-size: contain;
        background-repeat: no-repeat;

        // @media only screen and (-webkit-min-device-pixel-ratio: 2),
        //   only screen and (min--moz-device-pixel-ratio: 2),
        //   only screen and (-o-min-device-pixel-ratio: 2/1),
        //   only screen and (min-device-pixel-ratio: 2),
        //   only screen and (min-resolution: 192dpi),
        //   only screen and (min-resolution: 2dppx) {
        //   background-image: url(../img/sprite_icons@2x.png);
        // }
      }
    }
    &[data-value="funding"] {
      span {
        &::before {
          background-image: url(../img/assets/se-financer.png);
        }
      }
    }
    &[data-value="bootstraping"] {
      span {
        &::before {
          background-image: url(../img/assets/lancer.png);
        }
      }
    }
    &[data-value="sourcing"] {
      span {
        &::before {
          background-image: url(../img/assets/tree.png);
        }
      }
    }
    &[data-value="marketing"] {
      span {
        &::before {
          background-image: url(../img/assets/commercialiser.png);
        }
      }
    }
    &[data-value="innovating"] {
      span {
        &::before {
          background-image: url(../img/assets/innover.png);
        }
      }
    }
    &[data-value="exporting"] {
      span {
        &::before {
          background-image: url(../img/assets/export.png);
        }
      }
    }
    &[data-value="animating"] {
      span {
        &::before {
          background-image: url(../img/assets/animer-sa-marque.png);
        }
      }
    }
    &[data-value="training"] {
      span {
        &::before {
          background-image: url(../img/assets/learn.png);
        }
      }
    }
    &[data-value="to_be_accompanied"] {
      span {
        &::before {
          background-image: url(../img/assets/grow.svg);
        }
      }
    }
  }
}
