@function calculateRem($size) {
  $remSize: $size / 16px;
  @return $remSize * 1rem;
}

@mixin font-size($size) {
  font-size: $size;
  font-size: calculateRem($size);
}

p {
  @include font-size(16px);
}

h1 {
  @include font-size(34px);
}

h2 {
  @include font-size(28px);
}

h3 {
  @include font-size(22px);
}

h4 {
  @include font-size(20px);
}

h5 {
  @include font-size(18px);
}

h6 {
  @include font-size(16px);
}

h2,
h3,
h4,
h5,
h6 {
  color: theme-color("black-project");
  font-family: $montserrat-bold;
}

body:not(#id_index) {
  h2 {
    color: theme-color("secondary");
    position: relative;
    padding-bottom: 20px;
    margin-bottom: 20px;
    &::before,
    &::after {
      content: "";
      position: absolute;
      border-radius: 4px;
      height: 8px;
      background: theme-color("primary");
      bottom: 0;
    }

    &::after {
      width: 30px;
      left: 12px;
    }
    &::before {
      width: 8px;
      left: 0px;
    }
  }
}

@include media-breakpoint-down(xs) {
  h2 {
    font-size: 24px;
  }
  h3 {
    font-size: 20px;
  }
  h4 {
    font-size: 18px;
  }
}

h1.title {
  font-size: 1.6rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  margin-bottom: 2rem;
  border-bottom: 1px solid #eee;
}

.handofsean + h2 {
  margin-top: -1rem;
}
