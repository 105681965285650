#id_landing{
  .fobi-form{
    .control-label{
      color: #222;
    }
    .form-group{

      &.control-group-nom_de_la_personne_en_charge_du_dossier,
      &.control-group-prenom, &.control-group-fonction,
      &.control-group-code_postal,
      &.control-group-commune,
      &.control-group-choix_du_departement,
      &.control-group-departement,
      &.control-group-code_naf,
      &.control-group-statut_juridique,
      &.control-group-siret,
      &.control-group-effectifs,
      &.control-group-ca_ht,
      &.control-group-fonds_propres{
        @include media-breakpoint-up(sm) {
          @include make-col(6);
        }
        @include media-breakpoint-up(lg) {
          @include make-col(4);
        }
      }
      &.control-group-telephone,
      &.control-group-mail
      {
        @include media-breakpoint-up(sm) {
          @include make-col(6);
        }
        @include media-breakpoint-up(lg) {
          @include make-col(6);
        }
      }
    }
  }


  .breadcrumb li.active, .breadcrumb li::before{
    color: #222;
  }
  header{
    background-color: darken(theme-color('primary'), 20);
    background-image: url("../img/l-bg.jpeg");
    background-size: cover;
    background-blend-mode: multiply;
    color: white;
    margin-bottom: 2rem;
    position: relative;
    padding-bottom: 2rem;
    &.wave{
      padding-bottom: 1rem;
      &::after{
        content: "";
        background-image: url(../img/curve-border.svg);
        background-size: cover;
        height: 88px;
        width: 100%;
        position: absolute;
        bottom: -2px;
        left: 0;
        right: 0;
      }
    }
    >*{
      position: relative;
      z-index: 1;
    }
    h1{
      font-family: $montserrat-bold;
      font-size: 2.5rem;
      margin-bottom: 1rem;
      &::after{
        content: "";
        width: 40px;
        height: 5px;
        background-color: white;
        display: block;
        margin-bottom: 1rem;
        margin-top: 1rem;
      }
    }
  }
  .sponsors{
    background-color: #fff;
    border-radius: 8px;
    color:theme-color('dark');
    margin-bottom: -3rem;
    &::before, &::after
    {
      z-index: -1;
      position: absolute;
      content: "";
      bottom: 10px;
      left: 30px;
      width: 50%;
      top: 80%;
      max-width:300px;
      background: #777;
      box-shadow: 0 35px 20px #777;
      transform: rotate(-8deg);
    }
    &::after{
      transform: rotate(8deg);
      right: 30px;
      left: auto;
    }
  }
  .p_intro{
    padding-bottom: 0rem;
    font-family: $montserrat-medium;
    color: #444;
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .p_small{
    font-size: 80%;
    margin-bottom: 0.3rem;
  }
  .gray-background{
    background-color: #f5F5F5;
  }
  .column-background{
    border-radius: 8px;
    overflow: visible !important;
  }
  .multicolumn-background-image{
    background-blend-mode: multiply;
  }
  h2{
    // all: unset !important;
    font-family: $montserrat-bold;
    color: #222 !important;
    padding-left: 20px;
    padding-bottom: 0 !important;
    &::after{
      all: unset !important;
    }
    &::before{
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 4px;
      height: 100%;
      background-color: theme-color('primary');
    }
    strong{
      text-transform: uppercase;
      font-family: $montserrat-regular;
      font-size: 80%;
    }
  }
  .owl-dots, .owl-nav{
    button{

      border-radius: 20px;
      &:hover, &:active, &:focus{
        outline: none;
      }
    }
  }
  .owl-dot{
    &.active, &:hover{
      span{
        background-color: theme-color('primary');
      }
    }
  }
  .owl-nav{
    button{
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 40px;
      height: 40px;
      box-shadow: 0 20px 40px 0 rgba(0, 0, 0, 0.3);
      background-color: white;
      &.disabled{
        opacity: .2;
      }
      &:hover, &:active, &:focus{
        color: #222;
      }

      &.owl-prev{
        left: -50px;
      }
      &.owl-next{
        right: -50px;
      }
    }
  }
  .slider_landing{
    margin-top: 3rem;
    margin-bottom: 3rem;
    .item{
      text-align: center;
      .content{
        padding: 10px;
        margin-top: 10px;
      }
    }

  }
  .teaser{
    margin-bottom: 1rem;
    transition: all .3s ease;
    position: relative;
    border-radius: 8px;
    max-width: 90%;
    .widget{
      border-radius: 10px;
    }
    &::after{
      display: block;
      position: absolute;
      left: 0%;
      top: 50%;
      text-align: left;
      width:5px;
      height: 70%;
      border-radius: 0 5px 5px 0;
      transition: .3s;
      transform: translateY(-50%);
      content: "";
    }

    a{
      &:hover{
        text-decoration: none;
      }
    }
    .teaser-content{
      &::after{
        content: "J'envoie ma demande";
        font-size: 90%;
        display: inline-block;
        padding: 1rem;
      }
    }
    &.l_primary{
      // border-left: 10px solid theme-color('primary');
      &::after{
        background-color: theme-color('primary');
      }
      h3, .teaser-content::after{
        color: theme-color('primary');
      }
      &:hover{
        background-color: theme-color('primary');
      }
      .teaser-content{
        &::after{
          background-color: theme-color('primary');
          color: white;
        }
      }
    }
    &.l_secondary{
      // border-left: 10px solid theme-color('secondary');
      @include media-breakpoint-up(lg) {
        transform: translateX(10%);
      }
      &::after{
        background-color: theme-color('secondary');
      }
      h3, .teaser-content::after{
        color: theme-color('secondary');
      }
      &:hover{
        background-color: theme-color('secondary');
      }
      .teaser-content{
        &::after{
          background-color: theme-color('secondary');
          color: white;
        }
      }
    }
    &.l_third{
      &::after{
        background-color: theme-color('orange');
      }
      h3, .teaser-content::after{
        color: theme-color('orange');
      }
      &:hover{
        background-color: theme-color('orange');
      }
      .teaser-content{
        &::after{
          background-color: theme-color('orange');
          color: white;
        }
      }
    }
    &:hover{
      color: white;
      h3, p, .teaser-content::after{
        color: color('white');
      }
    }
  }
}

.spacing_20{
  height: 60px;
}




figure.testimonial {

  position: relative;
  margin: 10px;
  min-width: 400px;
  max-width: 70%;
  width: 100%;
  color: #333;
  text-align: left;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  align-items: center;
  background-color: white;
  border-radius: 8px;
  // &::after{
    //   content: "";
    //   clear: both;
    //   display: table;
    // }
  img {
    max-width: 40%;
    vertical-align: middle;
    border-radius: 0 8px 8px 0;
    object-fit: cover;
  }
  figcaption {
    background-color: #ffffff;
    border-radius: 8px;
  }
  blockquote {
    position: relative;
    padding: 25px 50px 25px 50px;
    font-size: 0.9rem;
    font-family: $montserrat-medium;
    text-align: left;
    margin: 0;
    line-height: 1.6em;
    font-style: italic;
    &::after, &::before{
      font-family: 'FontAwesome';
      content: "\201C";
      position: absolute;
      font-size: 50px;
      opacity: 0.3;
      font-style: normal;
    }
    &::before {
      top: 25px;
      left: 20px;
    }
    &::after {
      content: "\201D";
      right: 20px;
      bottom: 0px;
    }
  }

  .author {
    padding: 10px 15px;
    margin: 0;
    text-transform: uppercase;
    *{
      color: theme-color('primary');
    }
    h5 {
      opacity: 0.8;
      margin: 0;
      font-weight: 800;
    }
  }
}



