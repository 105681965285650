.login{
  padding:3rem;
  background-color: $white;
  border: 1px solid  #eee;
  legend{
    text-align: center;
    color: theme-color("primary");

  }
  .hidden{
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  button{
    width:100%;
  }
  // label{
    //     background-color: #d0d0d0;
    //     border-bottom-right-radius: 0;
    //     border-top-right-radius: 0;
    //     padding: 1rem;
    //     padding-left: 20px;
    //     padding-left: 1.25rem;
    //     padding-right: 20px;
    //     padding-right: 1.25rem;
    //     margin-bottom:0;
    // }
  .icon {
    display: inline-block;
    fill: #606468;
    font-size: 16px;
    font-size: 1rem;
    height: 1em;
    vertical-align: middle;
    width: 1em;
  }
  input{
    background-color: #e4e4e4;
    border-radius: 0.25rem;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    width:100%;
    padding: 16px;
    padding: 1rem;
    margin-bottom: 10px;
  }
}
input{
  background-image: none;
  border: 0;
  color: inherit;
  font: inherit;
  margin: 0;
  outline: 0;
  padding: 0;
  transition: background-color 0.3s;
}
.form__field{
  margin: 14px;
  margin: 0.875rem;
}

.form__input{
  flex:1;
}


form{
  label, .label{
    font-weight:bold;
    font-size: 14px;
  }
  .intl-tel-input{
    min-width: 100%;
  }
  legend{
    font-size: 1.1rem;
    font-weight: bold;
    border-bottom: 1px solid #eee;
    padding: .5rem;
  }
  fieldset{
    margin-bottom: 1rem;
    border: 1px solid #eee;
    padding: 1rem;
  }
  .error{
    color: $red;
    @extend .invalid-feedback;
  }
  .has-error{
    label, .label{
      color: $red;
    }
    input, select{
      @extend .is-invalid;
      box-shadow: 0px 0px 10px 0px $red;
    }
    .error{
      display: block;
    }
  }
  .control-group{
    margin-bottom:10px;
  }
}

#id_adherent{
  //background-color: #f3f3f3;
  @media (max-width: 991.98px){
    #wrap {
      margin-top: 0;
      overflow-x: hidden;
    }
  }
  h1{
    font-size: 1.3rem;
    font-weight:bold;
    letter-spacing: 0px;
    text-transform: uppercase;
    color: theme-color('primary');
  }
  .form-control{
    height: auto;
  }
}

.adherent__form{
  fieldset{
    background-color: $white;
  }
  legend{
    display:inline-block;
    background-color:theme-color('secondary');
    color:$white;
  }
  .tab-content{
    padding: 1rem;
    border: 1px solid transparent;
    border-color: #fff #dee2e6 #dee2e6;
    iframe{
      max-width: 100%;
      width: 100%;
    }
  }



}
.language-tabs{
  li{
    @extend .nav-item;
    a{
      @extend .nav-link;
      border: 1px solid transparent;
      &.active{
        color: #495057;
        background-color: #fff;
        border-color: #dee2e6 #dee2e6 #fff;
      }

    }
  }
}

.form-group{
  .controls{
    input[type="checkbox"]{
      display: inline-block;
      width:auto;
      margin-left: 10px;
    }
    ul{
      list-style-type: none;
    }
  }
}

.help-block{
  font-size: 12px;
  margin-top: 5px;
  @extend .text-muted;
}


input[type="file"] {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
  &:focus+label {
    outline: 1px dotted $cyan;
    outline: -webkit-focus-ring-color auto 5px;
  }
}

input[type="file"]+label {
  color: white;
  background-color: $black;
  display: inline-block;
  cursor: pointer;
  padding: 10px;
  text-align: center;
  border-radius: 3px;
}


.django-ckeditor-widget{
  min-width: 100%;
  max-width: 100%;
  .cke{
    margin: 0 auto;
    min-width: 100%;
    max-width: 100%;
  }
}

.recapitulatif-valider, .recapitulatif-cga, .recapitulatif-visibility, .recapitulatif-opt_in_region {
  background: rgba($green, .4);
  padding: 1rem;
  //color: $white;
  label{
    font-size: 16px;
  }
  &.has-error{
    background-color: rgba($red, .1);
  }
  a{
    color: #53713c;
    text-decoration: underline;
  }
}


input::-webkit-input-placeholder {
  color:#ccc !important;
}
input::-moz-placeholder {
  color: #ccc !important;
}
input:-moz-placeholder { /* Older versions of Firefox */
  color: #ccc !important;
}
input:-ms-input-placeholder {
  color: #ccc !important;
}


.helptext{
  display: block;
  ul{
    list-style-type: none;
    padding: 10px;
    @extend .alert;
    @extend .alert-primary;
    li{
      margin-bottom: 5px;
      font-size: 14px;
    }
  }
}

.fobi-form{
  @include make-row();
  .form-footer{
    min-width: 100%;
  }
  .infos{
    margin: 15px ;
  }
  div[class^='control-group-content_text_'], div[class*='control-group-content_text_']{
    margin-top: 2rem;
    p{

      font-weight: bold;
      background-color: rgba(theme-color('secondary'), .1);
      padding: 10px;
      color: theme-color('secondary');
      font-size: 20px;
      border-left: 4px solid theme-color('seondary');
    }
  }
  .form-group{
    @include make-col-ready();
    @include media-breakpoint-up(sm) {
      @include make-col(12);
    }
    @include media-breakpoint-up(lg) {
      @include make-col(12);
    }

    &.control-group-votre_prenom,
    &.control-group-votre_nom,
    &.control-group-votre_e_mail,
    &.control-group-votre_numero_de_telephone,
    &.control-group-votre_adresse,
    &.control-group-votre_entreprise,
    &.control-group-votre_fonction,
    &.control-group-domaine_dactivite{
      @include media-breakpoint-up(sm) {
        @include make-col(6);
      }
      @include media-breakpoint-up(lg) {
        @include make-col(6);
      }
    }
    &.control-group-nom_de_la_personne_en_charge_du_dossie{
      @include media-breakpoint-up(sm) {
        @include make-col(6);
      }
      @include media-breakpoint-up(lg) {
        @include make-col(4);
      }
    }
    &.control-group-attentes_formation{
      textarea{
        height: 100px;
      }
    }
  }
  div[class^='control-group-content_text']{
    @include media-breakpoint-up(sm) {
      @include make-col(12);
    }
    @include media-breakpoint-up(lg) {
      @include make-col(12);
    }
  }
}


form.compact{
  @include media-breakpoint-up(sm) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
    >.w-100{
      grid-column: auto / span 3;
    }
    .form-group {
      padding: 0;
      &.control-group-vos_besoins,
      &.control-group-siege_social,
      &.control-group-etes_vous_adherent_au_cluster_bio{
        min-width: 100%;
        grid-column: auto / span 3;
      }
    }
    .form-footer{
      min-width: 100%;
      grid-column: auto / span 3;
      button{
        display: inline-block;
        width: auto !important;
      }
    }
    div[class*="control-group-content"]{
      grid-column: auto / span 3;
    }
  }
}

.remove__field {
  position: absolute;
  right: -20px;
  top: -20px;
  background-color: lighten($red, 5);
  height: 40px;
  width: 40px;
  border-radius: 50%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $white;
  cursor: pointer;
}

.produit__item {
  position: relative;
  padding: 10px;
  border: 1px solid #eee;
  margin-bottom: 10px;

  #div_id_cible,
  [id^="div_id_produits-"][id$="-cible"] {

    #id_cible,
    [id^="id_produits-"][id$="-cible"] {
      li.unavailable .cible-label {
        text-decoration-line: line-through;
        opacity: 0.5;
      }
    }
  }
}

.controls {
  ul {
    padding-left: 0;
    list-style-type: none;
  }

  label {
    margin-right: 10px;
    display: inline-block;
    &+label{
      display: block;
      padding-left: 40px;
    }
  }
}

.form-control[type="checkbox"]{
  height: auto;
}

.cible {
  ul.form-control {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
    height: auto;

    li {
      flex: 1 0 50%;
    }
    [type="checkbox"].form-control{
      height: auto;
    }
    .sous-categories{
      padding-left: 30px;
    }
  }
}
