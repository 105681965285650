.menu__box {
  margin-bottom: 3rem;

  transition: all .3s ease;

  a {
    display: block;
  }

  .img__box {
    position: relative;

    img {
      width: 100%;
    }
  }

  .caption,
  .img__box {
    border: 1px solid #eee;
  }

  .caption {
    background: $white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
    min-height: 100px;
    border-top: 0;
    text-align: center;

    h3 {
      font-size: 16px;
      font-family: $montserrat_medium;

      a {
        color: inherit;
      }
    }
  }

  a.caption__link {
    opacity: 0;
    transition: all .3s ease;
  }

  &:hover {
    transform: scale(1.05);
    z-index: 99;

    a.caption__link {
      opacity: 1;
    }
  }

  .icon__private {
    height: 36px;
    width: 36px;
    border-radius: 36px;
    position: absolute;
    background-color: rgba(#222, .9);
    border: 1px solid #222;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    display: none;
    position: absolute;
    top: -15px;
    left: 20px;
  }

  &.private {

    .icon__private {
      display: flex;
    }

    .img__box {
      border-color: theme-color('primary');
    }

    .caption {
      background-color: theme-color('primary');
      border-color: darken(theme-color('primary'), 5);
      color: $white;
      position: relative;

      * {
        color: $white;
      }
    }
  }
}

.menu__wrapper{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  .menu__item{
    position: relative;
    .icon__private{
      display: none;
    }
    &.private{
      .icon__private{
        display: block;
      }
    }
    .menu__content{
      position: absolute;
      bottom: 15px;
      right: 15px;
      left: 15px;
      top: 15px;
      display: flex;
      flex-direction: column;
      text-align: center;
      justify-content: end;
      padding: 10px;
      color: white;
      border: 2px solid rgba(#fff, 0);
      transition: all .3s;
      border-radius: 5px;
      color: white;
      .description{
        opacity: 0;
        transform: translateY(100%);
        height: 0;
        overflow: hidden;
        transition: all .3s ease;
      }
    }
    a, h3{
      color: white;
      transition: all .3s ease;
    }
    .img__box{
      a{
        display: block;
      }
      img{
        min-width: 100%;
        height: auto;
        border-radius: 5px;
      }
      &::after{
        position: absolute;
        content: "";
        left: 0;
        right: 0;
        bottom: 0;
        top:0;
        background-image: linear-gradient(180deg, rgba(0,0,0,0.00) 57%, #000000 100%);
        border-radius: 5px;
        transition: all .3s ease;
      }
    }
    &:hover{
      .menu__content{
        border: 2px solid #fff;
      }
      h3, .description{
        transform: translateY(-20px);
      }
      .img__box{
        &::after{
          background-image: linear-gradient(180deg, rgba(0,0,0,0.00) 0%, #000000 100%);
        }
      }
      .description{
        opacity: 1;
        height: 150px;
      }
    }
  }
}
