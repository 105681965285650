.footer-site {
  background-image: url(../img/footer_bg.svg);
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 110px;
  padding-bottom: 50px;
  @include media-breakpoint-down(sm) {
    padding-bottom: 100px;
    background-color: theme-color("primary");
    padding-top: 30px;
    text-align: center;
    .col {
      min-width: 100%;
    }
  }
  position: relative;
  &::after {
    position: absolute;
    right: 0;
    bottom: 0;
    @include media-breakpoint-up(sm) {
      content: url(../img/footer_bg2.svg);
      height: 190px;
      width: 278px;
    }
    @include media-breakpoint-down(sm) {
      content: '';
      background-image:  url(../img/footer_bg2.svg);
      height: 130px;
      width: 100%;
      left: 0;
      background-size: cover;
    }
  }
  .kapt {
    position: absolute;
    bottom: 30px;
    right: 30px;
    color: white;
    z-index: 1;
    font-size: 14px;
    img {
      filter: brightness(0) invert(1);
    }
  }
  ul {
    li {
      margin: 0 10px;
      a {
        color: theme-color("dark");
        font-family: $montserrat-medium;
        &:hover {
          color: theme-color("primary");
        }
      }
    }
  }
  .notices {
    a {
      font-size: 14px;
    }
  }
  p {
    strong {
      font-family: $montserrat-bold;
      color: theme-color("dark");
      font-size: 18px;
    }
  }
  address {
    a {
      font-family: $montserrat-bold;
      color: theme-color("secondary");
    }
  }
}
.footer_desc {
  p {
    font-family: $montserrat-semi;
    font-size: 14px;
  }
}

.footer__bottom {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;
  @include media-breakpoint-down(md) {
    flex-direction: column;
    ul {
      margin-bottom: 20px;
      min-width: 100%;
    }
  }
  ul li {
    margin: 0;
    a {
      text-transform: none;
      font-size: 13px !important;
      padding: 0 20px;
      img {
        max-width: 160px;
        height: auto;
        mix-blend-mode: multiply;
      }
    }
  }
  ul {
    align-items: center;
  }

}
.footer__landing{
  padding-bottom: 3rem;
  .footer__bottom{
    @include media-breakpoint-down(sm) {
      .notices{
        flex-direction: column;
        gap: 1rem;
      }
    }
  }
}
.espace_membres {
  .footer-site {
    margin-top: 2rem !important ;
    background-color: white !important;
  }
}
