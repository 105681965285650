.marker-cluster-small {
  background-color: transparentize(theme-color('dark-gray'), .6);
}

.marker-cluster-small div {
  background-color: theme-color('dark-gray');
}

.marker-cluster-medium {
  background-color: transparentize(theme-color('dark-gray'), .6);
}

.marker-cluster-medium div {
  background-color: theme-color('dark-gray');
}

.marker-cluster-large {
  background-color: transparentize(theme-color('dark-gray'), .6);
}

.marker-cluster-large div {
  background-color: theme-color('dark-gray');
}

/* IE 6-8 fallback colors */
.leaflet-oldie .marker-cluster-small {
  background-color: theme-color('dark-gray');
}

.leaflet-oldie .marker-cluster-small div {
  background-color: theme-color('dark-gray');
}

.leaflet-oldie .marker-cluster-medium {
  background-color: theme-color('dark-gray');
}

.leaflet-oldie .marker-cluster-medium div {
  background-color: theme-color('dark-gray');
}

.leaflet-oldie .marker-cluster-large {
  background-color: theme-color('dark-gray');
}

.leaflet-oldie .marker-cluster-large div {
  background-color: theme-color('dark-gray');
}

.marker-cluster {
  background-clip: padding-box;
  border-radius: 20px;
}

.marker-cluster div {
  width: 30px;
  height: 30px;
  margin-left: 5px;
  margin-top: 5px;

  text-align: center;
  border-radius: 15px;
  font: 12px $montserrat-regular;
  color: $white;
}

.marker-cluster span {
  line-height: 30px;
}
